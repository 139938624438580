import { ToolbarItemUtilities, IconSpecUtilities, BadgeType, CommonToolbarItem, ConditionalBooleanValue, ConditionalStringValue } from "@itwin/appui-abstract";
import { ToolbarUsage, ToolbarOrientation, ToolItemDef, ToolbarHelper, CustomItemDef, CoreTools, SyncUiEventDispatcher, UiFramework, WidgetState, ToolbarItem, FrontstageDef, StagePanelDef, StagePanelState, UiItemsManager, FRONTSTAGE_SETTINGS_NAMESPACE } from "@itwin/appui-react";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, ScreenViewport } from "@itwin/core-frontend";
import { HighlightBracings } from "../tools/HighlightBracings";
import { HighlightEquipment } from "../tools/HighlightEquipment";
import { HighlightMount } from "../tools/HighlightMount";
import { HighlightTower } from "../tools/HighlightTower";
import { SvgEdit, SvgFilter, SvgModel, SvgTable } from "@itwin/itwinui-icons-react";
import EditDialogComponent, { targetComponentList } from "./EditDialogComponent";
import { AddBoundingBoxTool } from "./tools/AddBoundingBoxTool";
import TrackTelemetryApiClient from "../api/trackTelemetry";
import { ModifyHandleDecoration } from "./tools/modification/ModifyHandleDecoration";
import { HighlightGratings } from "../tools/HighlightGratings";
import { HighlightFeedline } from "../tools/HighlightFeedline";
import DeleteMount from "./delete-mount/delete-mount";
import { HighlightPinAnnotations } from "./tools/HighlightPinAnnotation";
import { AddPinAnnotationTool } from "./tools/AddPinAnnotationTool";
import PinTagClient from "../api/pinTagClient";
import realityDataSvg from "./icon/svg/realityData.svg";
import ConfirmationModal from "./ConfirmationModal";
import { AddEditPolygonShapeTool } from "./tools/AddEditPolygonShapeTool";
import { HighlightGratingTool } from "./tools/HighlightGratingTool";
import { AddEditBracingTool } from "./tools/AddEditBracingTool";
import { store } from "../../store/rootReducer";
import { DTVActions } from "../../store/Actions";
import { FeedlineSectionType, ObjectCategoryTypes, initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";
import { MountDecorator } from "../tools/decorators/MountDecorator";
import { EquipmentType, SyncUiEventIds, selectionObjective } from "../../store/redux-types";
import { DefectsDecorator } from "../tools/decorators/DefectsDecorator";
import AddMountElevationPopup from "./tools/AddMountElevationPopup";
import React from "react";
import { setMountingsData, setSelectedObjectStructure, updateBuilt3DObjectIdsMap } from "../../store/detectedData/apiDataActionTypes";
import { BracingDecorator } from "../tools/decorators/BracingDecorator";
import { customToolbarButtonsInterface } from "../../ui/providers/DTVUiItemsProvider";
import { HighlightLadder } from "../tools/HighlightLadder";
import { Box, Point3d } from "@itwin/core-geometry";
import { TowerStructureDecorator } from "../tools/decorators/TowerStructureDecorator";
import { PolygonShapeDecorator } from "../tools/decorators/PolygonShapeDecorator";
import { HighlightMountTool } from "./tools/HighlightMountTool";
import { AddLadderTool } from "./tools/AddLadderTool";
import { LadderDecorator } from "../tools/decorators/LadderDecorator";
import ladder from "../components/icon/svg/ladder.svg"
import EquipmentClient from "../api/equipment";
import { ConfigManager } from "../../config/ConfigManager";
import { UserRequest, UserRequestApp, UserRequestSource } from "../../services/graphql/database-api/userRequest";
import DatabaseApiClient from "../../services/graphql/database-api/database-api-client";
import { HighlightAllDetectedObjects } from "../tools/HighlightAllDetectedObjects";
import { HighlightFeeders } from "../tools/HighlightFeeders";
import { CreateGeometryMode, FeedlineData, FeedlineDecorator } from "../tools/decorators/FeedlineDecorator";
import { AddFeedlineTool } from "./tools/AddFeedlineTool";
import { getUnlinkedFeedline } from "../tools/DetectedFeedlineEditHandler";
import { EquipmentData, ShapeDecorator } from "../tools/decorators/ShapeDecorator";
import { HighlightShape } from "../tools/HighlightShape";
import { DetectedGeometryType, PlacementMode, PlacementTool } from "./tools/PlacementTool";
import { EquipmentEditToolUiProvider } from "./tools/EquipmentEditToolUiProvider";
import Place3DObjectApi from "./tools/Place3DObjectApi";
import { Id64String } from "@itwin/core-bentley";
import { TowerEditToolUiProvider } from "./tools/TowerEditToolUiProvider";
import { OutlineShapeTool } from "./tools/OutlineShapeTool";
import { AnnotationDecorator } from "../tools/decorators/AnnotationsDecorator";
import { DimensionMode } from "../tools/CustomEquipmentHandler";

// const listener = () => {
//     setProjectIdUrl(currentState);
// }

// store.subscribe(listener);
export const executeRADFunction = async () => {
    await UiFramework.frontstages.setActiveFrontstage("sandbox:two-viewports-frontstage");
    if(!UiFramework.content.dialogs.active){
    UiFramework.content.dialogs.open(
        // <PropertyEditModal
        //     screenLoc={{ x: 300, y: 100 }}
        //     width={500}
        //     nameVals={temp}
        //     // cellHeadings={[{name: `Elevation ${getUnit()}`, styles: {parentClass: "col-6", elementClass: "col-12 pt-3"}}, {name: "Operator", styles: {parentClass: "col-6", elementClass: "col-12 pt-3"}}]}
        //     openOClose={true}
        //     // eventHandlers={{onChange: onPropertyModalChange, btnClickHandler: onModalBtnClick }}
        //     eventHandlers={{ onChange: () => { }, btnClickHandler: () => { } }}
        //     btns={[]}
        //     title={`Add RAD Level`} children={undefined}
        //  />,

        // <PropertyEditorComponent
        //     openOClose={true}
        //     structureType={pecStrucType.DIALOG}
        //     title={{label: "Add RAD Level"}} 
        //     grid={tempTablestruc} 
        //     gridButtons={[]} 
        //     eventHandlers={{ onChange: ()=>{}, onClick: onClickHandler }}
        //     width={800}
        //  />,

        <AddMountElevationPopup data={UiFramework.getIModelConnection()} />,
        "AddNewRad"
    )} else UiFramework.content.dialogs.close("AddNewRad");
    // ModalDialogManager.openDialog(
    //   <EditDialogComponent
    //   //   title="Frontstage Introduction"
    //     title={"Asset Condition"}
    //     message={
    //       ["This sample serves as a basic introduction to frontstages.",
    //         "It contains four miscellaneous frontstage examples.",
    //         "Close this dialog and use the button in the top left corner to switch between each frontstage.",
    //       ].join(" ")
    //     }
    //   />
    // );
  }
  
export const editOnClose = (decName: string = "", _reset: boolean = false, close: boolean = true) => {
    const isEditActive = store.getState().dtvState.applicationState.isEditModeActive;
    ModifyHandleDecoration.clear();
    if(close){
        UiFramework.dialogs.modeless.close("Edit Dialog");
        if(decName == "PolygonShapeDecorator"){
             UiFramework.dialogs.modeless.close("Add New Grating");
            UiFramework.dialogs.modeless.close("Edit Grating");
        }
    }
        
    switch (decName) {
        case "MountDecorator":
            const md = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
            // const thePipes = oldMountGraphics.pipes.length ? oldMountGraphics.pipes : md.pipes;
            // const theTori = oldMountGraphics.tori.length ? oldMountGraphics.tori : md.tori;
          
            if (md) {
                let i = md.pipes.findIndex((e) => e.uid.includes("_editMid"));
                if (i > -1) {
                    const prevEditingPipe = md.pipes[i];
                    //deleting old editing pipe and add it too
                    do {
                        i = md.pipes.findIndex((e) => e.uid.includes("_edit"));
                        if (i !== -1) {
                            md.objectIdMap.delete(md.nameIdMap.get(md.pipes[i].transientId) as string);
                            md.pipes.splice(i, 1);
                        }
                    } while (i !== -1);
                    MountDecorator.editing = false;
                    const name = prevEditingPipe.name.split("_edit")[0].split("@")[0];
                    if(_reset)md.addPipe(md.previousSelection?.startPos, md.previousSelection?.endPos, prevEditingPipe.thickness, prevEditingPipe.fillColor, name, prevEditingPipe.modelData);
                    else  md.addPipe(prevEditingPipe?.startPos,prevEditingPipe?.endPos, prevEditingPipe.thickness, prevEditingPipe.fillColor, name, prevEditingPipe.modelData);        
                }
                const allPipes = md.pipes;
                const theTori = md.tori;
                const allNameIds = md.nameIdMap;
                const allNameObjectIds = md.objectIdMap;
        
                md.terminate();
                MountDecorator.editing = false;
        
                md.pipes = allPipes;
                md.tori = theTori;
                md.nameIdMap = allNameIds;
                md.objectIdMap = allNameObjectIds;
                IModelApp.viewManager.invalidateDecorationsAllViews();
                IModelApp.viewManager.selectedView?.invalidateCachedDecorations(md);
                if(_reset)resetObjectIds(md)
            }
                    
            break;
        case "LadderDecorator":
                const ld = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
                // const thePipes = oldMountGraphics.pipes.length ? oldMountGraphics.pipes : md.pipes;
                // const theTori = oldMountGraphics.tori.length ? oldMountGraphics.tori : md.tori;
              
                if (ld) {
                    let i = ld.pipes.findIndex((e) => e.uid.includes("_editMid"));
                    if (i > -1) {
                        const prevEditingPipe = ld.pipes[i];
                        //deleting old editing pipe and add it too
                        do {
                            i = ld.pipes.findIndex((e) => e.uid.includes("_edit"));
                            if (i !== -1) {
                                ld.objectIdMap.delete(ld.nameIdMap.get(ld.pipes[i].transientId) as string);
                                ld.pipes.splice(i, 1);
                            }
                        } while (i !== -1);
                        if(ld.otherSelectedLadder != null && _reset){
                            let pipes = ld.pipes.filter(pipe=>pipe.name != ld.otherSelectedLadder.name)
                            ld.pipes = [...pipes]
                            ld.addPipe(ld.otherSelectedLadder?.startPos, ld.otherSelectedLadder?.endPos, prevEditingPipe.thickness, prevEditingPipe.fillColor, ld.otherSelectedLadder.name, ld.otherSelectedLadder.modelData);
                        }
                        ld.otherSelectedLadder = null;
                        LadderDecorator.editing = false;
                        const name = prevEditingPipe.name.split("_edit")[0].split("@")[0];
                        if(_reset){
                            ld.addPipe(ld.previousSelection?.startPos, ld.previousSelection?.endPos, prevEditingPipe.thickness, prevEditingPipe.fillColor, name, prevEditingPipe.modelData);
                            ld.setDataForLadder(ld.previousSelection)
                        }
                        else {ld.addPipe(prevEditingPipe.startPos, prevEditingPipe.endPos, prevEditingPipe.thickness, prevEditingPipe.fillColor, name, prevEditingPipe.modelData);}
                    }
                    const allPipes = ld.pipes;
                    const theTori = ld.tori;
                    const allNameIds = ld.nameIdMap;
                    const allNameObjectIds = ld.objectIdMap;
            
                    ld.terminate();
                    LadderDecorator.editing = false;
            
                    ld.pipes = allPipes;
                    ld.tori = theTori;
                    ld.nameIdMap = allNameIds;
                    ld.objectIdMap = allNameObjectIds;
                    IModelApp.viewManager.invalidateDecorationsAllViews();
                    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(ld);
                    if(_reset)resetObjectIds(ld)
                }
                        
                break;
        case "TowerStructureDecorator": {
            const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
            if(towDec.previousSelection){
                towDec.hideMarkers = !_reset;
                const index = towDec.backupCylinders.findIndex(e => e.name.includes(towDec.previousSelection!.name));
                towDec.backupCylinders[index].startPos = towDec.previousSelection?.startPos;
                towDec.backupCylinders[index].endPos = towDec.previousSelection?.endPos;
                let towData = towDec.getTowerGeometry;      
                towDec.towerCylinders = towDec.backupCylinders;
                towDec.terminate()
                towDec.loadShapes(towData);
                IModelApp.viewManager.invalidateDecorationsAllViews();
                IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
                resetObjectIds(towDec);
            }
        }
        break;
        case "PolygonShapeDecorator":{
            if(_reset){
                const pd = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes(decName))[0] as PolygonShapeDecorator;
                pd.resetGrating(pd.lastSelection);
                resetObjectIds(pd)
            }
        }
        case "FeedlineDecorator":{
            const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
            // const newJson = decorator.getUneditedBoxJson();
            // const newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|end/));
            // decorator.shapes = newShapes;
            // decorator.objectIdMap.clear();
            // // if(!decorator.untouchedFeedlineData.Id.match(/CustomFeedline/)){
            //     decorator.createGeometry(newJson)
            //     // SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.Nothing_Selected);
            //     SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Feedline_UnSelected);
            // // }
            // IModelApp.viewManager.invalidateDecorationsAllViews();
            // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
            const feedId = decorator.selectedFeedline.modelId;
            // const theSegments = decorator.shapes.filter(e=>e.name == feedId.split('_')[0]);
            const newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
            decorator.shapes = newShapes;

            // getUnlinkedFeedline(theSegments.map(e=>e.modelData!));
                
            const storedFl = store.getState().detectedData.feedLinesData;
            // if(!decorator.untouchedFeedlineData!.Id.match(/CustomFeedline/)){
            if(storedFl.findIndex(e=>e.clusterId == parseInt(feedId)) != -1){
                if(decorator.untouchedFeedlineData!.FeedlineType.type == FeedlineSectionType.Segmented){
                    decorator.untouchedFeedlineSegments.forEach(e=>decorator.createGeometry(e));
                } else decorator.createGeometry(decorator.untouchedFeedlineData!, CreateGeometryMode.New);
                // SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.Nothing_Selected);
                SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Feedline_UnSelected);
            } else {
            const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==feedId);
            if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);
            } 
            IModelApp.viewManager.invalidateDecorationsAllViews();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);

            // const iModelConnection = UiFramework.getIModelConnection();
            // iModelConnection?.selectionSet.emptyAll();
            if(_reset)resetObjectIds(decorator)
        }
        default:
            break;
    }
    store.dispatch(DTVActions.setEditModeFlag(!isEditActive));
}

export const resetObjectIds = (decorator: MountDecorator | TowerStructureDecorator | PolygonShapeDecorator | FeedlineDecorator | LadderDecorator | ShapeDecorator | BracingDecorator) => {
    const currentState = store.getState();
    const decName = decorator.decoratorName;
    const built3ObjectMaps = currentState.detectedData.built3DObjectIdMaps;
    let theNameObjectIds = decorator.objectIdMap;
    let newIdVals: any[] = [];
    SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Equipment_UnSelected);    
    switch(decName){
        case "FeedlineDecorator": {
            newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/feederGeom/)));
            Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
        }
        break;
        case "ShapeDecorator": {
            decorator = decorator as ShapeDecorator;
            theNameObjectIds = decorator.resetObjectIds();
            newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/equipGeom/)));
            Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
        }
        break;
        case "MountDecorator": {
            newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/mountGeom/)));
            Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
        }
        break;
        case "TowerStructureDecorator": {
            decorator = decorator as TowerStructureDecorator;
            theNameObjectIds = decorator.resetObjectIds();
            newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/towerGeom/)));
            Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
        }
        break;
        case "PolygonShapeDecorator":{
            newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/gratingGeom/)));
            Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
        }
        break;
        case "LadderDecorator":{
            newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/ladderGeom/)));
            Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
        }
        break;
        case "BracingDecorator":{
            newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/bracingGrom/)));
            Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
        }
        break;
    }
    const newValIds: any[] = newIdVals.map((e: any[])=>[...e].reverse())
    store.dispatch(updateBuilt3DObjectIdsMap({idValues: newIdVals, valueIds: newValIds}));
}

export const editExecution = async (ExecutionType: selectionObjective, hideModal: boolean = true) => {
        // let item = "gratings";
    // let selectedMenu: any;
    // switch (item) {
    //   case "bracings":
    //     selectedMenu = <BracingsEditHandler/>;
    //     break;
    //   case "equipment":
    //     selectedMenu = <EquipmentEditHandler/>;
    //     break;
    //   case "gratings":
    //     selectedMenu = <GratingsEditHandler/>;
    //     break;
    
    //   default:
    //     break;
    // }
    
    
    
    // await UiFramework.frontstages.setActiveFrontstage("sandbox:two-viewports-frontstage");
    const currentState = store.getState();
    const selObjName = currentState.detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
    if(selObjName.length == 0)return;
    const isEditActive = currentState.dtvState.applicationState.isEditModeActive;
        let title = "";
    if(isEditActive && hideModal){
        editOnClose();
    } else {
        if(!isEditActive) {
            store.dispatch(DTVActions.setEditModeFlag(!isEditActive));
            UiFramework.dialogs.modeless.close("Edit Equipment Properties");
        }
        const selObj =  currentState.detectedData.selectedObjectInformation;
        const temp = {...selObj, objectProperties: {...selObj.objectProperties, selectionObjective: ExecutionType == selectionObjective.Add ? selectionObjective.Add : selectionObjective.Edit}};
        store.dispatch(setSelectedObjectStructure(temp));

        let target: targetComponentList = targetComponentList.RM_EquipmentEditor;
        switch (selObj.objectProperties.objectCategoryType) {
            case ObjectCategoryTypes.Equipment_RM:
            //     const objProps: objectProperties = {
            //         selectionObjective: "d:/BentleyProjects/FinalWeekMigration245/TowerSightDigitalTwin_FE/TowerSightiTwin/ClientApp/src/frontend/store/redux-types".Info,
            //         objectIds: "",
            //         objectDetails: undefined,
            //         objectCategoryType: "d:/BentleyProjects/FinalWeekMigration245/TowerSightDigitalTwin_FE/TowerSightiTwin/ClientApp/src/frontend/store/detectedData/apiDataTypes".Equipment,
            //         selectedObjectNST: {
            //             name: "",
            //             subType: EquipmentType.Antenna
            //         },
            //         clicked: clickType.SINGLE
            //     }
            //     const selObjDet: selectedObjectInformation = {
            //         ...selectedObjectInformationInitialState,
            //         objectProperties: 
            //         {
            //             selectionObjective: selectionObjective.Edit,
            //             selectedObjectNST: {name: selObj.objectProperties.selectedObject, subType: EquipmentType.Antenna},
            //             data: null
            //         }
            //     } 
            // }
                // store.dispatch(DTVActions.setSelectedAsBuiltObjectDetails(selObjDet))
                // ModifyHandleDecoration.clear();
                let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
                const shapeIndex = decorator!.shapes.findIndex((item) => item.modelData?.DisplayName === selObj.objectProperties.selectedObjectNST.name);
                const dmmWidget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("DesignModelMenu");
                dmmWidget?.setWidgetState(WidgetState.Closed);
                let theDimMode: DimensionMode = DimensionMode.Custom;
                const equipCurrJson = decorator.shapes[shapeIndex].modelData;
                if((equipCurrJson?.Manufacturer == "UPT:NewEquip" && equipCurrJson?.Model == "UPT:NewEquip-001") || (equipCurrJson?.Manufacturer == null && equipCurrJson?.Model == null))theDimMode = DimensionMode.Custom;
                else theDimMode = DimensionMode.Model;
                            
                // let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;
                if(!ModifyHandleDecoration._decorator && shapeIndex !== -1 && theDimMode == DimensionMode.Custom){
                    ModifyHandleDecoration.clear();
                    ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, [...selObj.objectProperties.objectIds][0], selObjName!, shapeIndex, decorator);
                }
                if (ModifyHandleDecoration._decorator && decorator.shapes[shapeIndex].modelData?.shapeId == null && theDimMode == DimensionMode.Custom) {
                    ModifyHandleDecoration._decorator._shape = decorator.shapes[shapeIndex].modelData?.geometryEntry?.geometry as Box;
                    ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
                    ModifyHandleDecoration._decorator._shapeName = selObj.objectProperties.selectedObjectNST.name!;
                    ModifyHandleDecoration._decorator.createClipShapeControls();
                } else ModifyHandleDecoration.clear();
                target = targetComponentList.RM_EquipmentEditor
                UiItemsManager.register(new EquipmentEditToolUiProvider(FRONTSTAGE_SETTINGS_NAMESPACE));
                const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("EquipmentEditWidget");
                if(widget?.state == WidgetState.Hidden)widget?.setWidgetState(WidgetState.Open);
                break;
            case ObjectCategoryTypes.Grating_RM:
                target = targetComponentList.RM_GratingEditor;
                title = "Edit Grating";
                AddEditPolygonShapeTool.poligonInfoArray = HighlightGratingTool.poligonInfoArray;
                break;
            case ObjectCategoryTypes.Bracings_RM:
                AddEditBracingTool.isBracingEditable = true;
                target = targetComponentList.RM_BracingEditor;
                title = "Edit Bracing";
                break;
            case ObjectCategoryTypes.Ladder_RM:
                target = targetComponentList.RM_LadderEditor;
                title = "Edit Ladder";
                // break;
            case ObjectCategoryTypes.Mount_RM: {
                UiFramework.dialogs.modeless.close("Edit Dialog");
                /** 
                * Task 1385568: Disable the Mount Edit workflows temporarily until the mount detection logic is fixed.
                const md = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
                const isEditActive = store.getState().dtvState.applicationState.isEditModeActive;
                if(hideModal || isEditActive)md.updatePipeSelectedForEdit();
                const prevPipe = md.selectedMount;
                if (prevPipe.modelData.orientation != "Circular") md.previousSelection = { name: prevPipe.name, startPos: new Point3d(prevPipe.startPos.x, prevPipe.startPos.y, prevPipe.startPos.z), endPos: new Point3d(prevPipe.endPos.x, prevPipe.endPos.y, prevPipe.endPos.z) };
                target = targetComponentList.RM_MountEditor;
                * */

            }
            break;
            case ObjectCategoryTypes.Tower_RM: {
                const dmmWidget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("DesignModelMenu");
                dmmWidget?.setWidgetState(WidgetState.Closed);
                target = targetComponentList.RM_TowerEditor;
                const td = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
                const selPart = td.towerCylinders.find(e=>e.name.match(selObjName));
                if(selPart){
                    td.previousSelection = {name: selObjName, startPos: new Point3d(selPart.startPos.x, selPart.startPos.y, selPart.startPos.z), endPos: new Point3d(selPart.endPos.x, selPart.endPos.y, selPart.endPos.z)};
                    UiItemsManager.register(new TowerEditToolUiProvider(FRONTSTAGE_SETTINGS_NAMESPACE));
                }
                const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("TowerEditWidget");
                if(widget?.state == WidgetState.Hidden)widget?.setWidgetState(WidgetState.Open);
            }
            break;
            case ObjectCategoryTypes.FeedLine_RM: {
                target = targetComponentList.RM_FeedlineEditor;
                // const td = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
                // const selPart = td.towerCylinders.filter(e=>e.name.match(selObjName))[0];
                // td.previousSelection = {name: selObjName, startPos: new Point3d(selPart.startPos.x, selPart.startPos.y, selPart.startPos.z), endPos: new Point3d(selPart.endPos.x, selPart.endPos.y, selPart.endPos.z)};
            }
            break;
            default:
                // target = targetComponentList.RM_EquipmentEditor;
                break;
        }
        const close = UiFramework.dialogs.modeless.count;
        if(!close && selObj.objectProperties.objectCategoryType != ObjectCategoryTypes.Equipment_RM && selObj.objectProperties.objectCategoryType != ObjectCategoryTypes.Tower_RM && selObj.objectProperties.objectCategoryType != ObjectCategoryTypes.Mount_RM && selObj.objectProperties.objectCategoryType != ObjectCategoryTypes.Defect_RM){
            UiFramework.dialogs.modeless.open(
                //   <VerticalTabs comp={selectedMenu}/>,
                <EditDialogComponent
                    //   title="Frontstage Introduction"
                    isOpen={true}
                    modalId="Edit Dialog"
                    targetComponent={target}
                    title={title}
                    onClose={editOnClose}
                    message={
                        ["This sample serves as a basic introduction to frontstages.",
                        "It contains four miscellaneous frontstage examples.",
                        "Close this dialog and use the button in the top left corner to switch between each frontstage.",
                        ].join(" ")
                    }
                />,
                "Edit Dialog"
            );
            }
    }
    const objectType = currentState.detectedData.selectedObjectInformation.objectProperties;
    // if(objectType.selectionObjective == selectionObjective.Info)



    SyncUiEventDispatcher.dispatchSyncUiEvent(`${objectType}-Edit-Mode-Active`);

    // if (this.state.planRepExist)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Please wait while we are checking for the planning report...", "", OutputMessageType.Toast));
    // const url = ConfigManager.INsiteImageUrl.replace("img?", "planningReport?");
}
 
const opElMapOnClose = () => {
    UiFramework.dialogs.modeless.close("Operator Elevation Mapping");  
}

const opElFilterOnClose = () => {
    UiFramework.dialogs.modeless.close("Operator Elevation Filtering");  
    const mountsData = store.getState().detectedData.mountingsData;
    IModelApp.tools.run(HighlightMountTool.toolId, mountsData, true, ["None"]);
    IModelApp.tools.run(OutlineShapeTool.toolId, ["None"]);
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Mount_UnHighlighted);
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Equipment_UnHighlighted);
}

export const opElExecution = async () => {
    if(UiFramework.dialogs.modeless.dialogs.filter(e=>e.id == "Operator Elevation Mapping").length)UiFramework.dialogs.modeless.close("Operator Elevation Mapping");
    else 
    UiFramework.dialogs.modeless.open(
        //   <VerticalTabs comp={selectedMenu}/>,
        <EditDialogComponent
            //   title="Frontstage Introduction"
            isOpen={true}
            title={"Operator Elevation Mapping"}
            targetComponent={targetComponentList.OpElEditor}
            onClose={opElMapOnClose}
            message={["This sample serves as a basic introduction to frontstages.",
                "It contains four miscellaneous frontstage examples.",
                "Close this dialog and use the button in the top left corner to switch between each frontstage.",
            ].join(" ")} 
            modalId={""}
        />,
        "Operator Elevation Mapping"
    );
}

export const opElFilterExecution = async () => {
    if(UiFramework.dialogs.modeless.dialogs.filter(e=>e.id == "Operator Elevation Filtering").length){
        UiFramework.dialogs.modeless.close("Operator Elevation Filtering");  
        const mountsData = store.getState().detectedData.mountingsData;
        IModelApp.tools.run(HighlightMountTool.toolId, mountsData, true, ["None"]);    }
    else 
    UiFramework.dialogs.modeless.open(
        <EditDialogComponent
            isOpen={true}
            title={"Operator Elevation Filtering"}
            targetComponent={targetComponentList.OpMountFilter}
            onClose={opElFilterOnClose}
            message={["This sample serves as a basic introduction to frontstages.",
                "It contains four miscellaneous frontstage examples.",
                "Close this dialog and use the button in the top left corner to switch between each frontstage.",
            ].join(" ")} 
            modalId={""}
        />,
        "Operator Elevation Filtering"
    );
}

export const runMountDetection = async () => {
    await IModelApp.tools.run(HighlightMountTool.toolId, {}, false, ["None"]);
    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Mount detection process has started & may take several minutes to complete. You will be notified via email when it is done.", "", OutputMessageType.Toast));
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Disable_Highlight_Mount);
    
    let sendData = {
        projectId: ConfigManager.projectId,
        sendNotification: true
    }

    let sendRequestData = JSON.stringify(sendData)
    
    let userRequst: UserRequest = {
        projectId: ConfigManager.projectId,
        source: UserRequestSource.MountIdentificationPly,
        requestData: sendRequestData,
        requestApp: UserRequestApp.PORTAL
    }
    
    let userRequestId = await DatabaseApiClient.addUserRequest(userRequst);
    if (userRequestId != "")await DatabaseApiClient.postUserRequestProcessMessage(userRequestId, UserRequestSource.MountIdentificationPly);
    
    let runLoop: any;
    runLoop = setInterval(async ()=>{
        const response = await DatabaseApiClient.UserRequestById(userRequestId);
        const status = getUserRequestStatus(response.userRequestById.status)
        switch (status){
            case "COMPLETED": {
                clearInterval(runLoop);
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "The mount detection process completed successfully. Updated Mounts Highlighted.", "", OutputMessageType.Toast));
                const mountsData = await EquipmentClient.getMountingsDataJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken!)
                store.dispatch(setMountingsData(mountsData));
                await resetMountingsData(mountsData);
                SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Enable_Highlight_Mount);
                break;
            }
            case "PARTIALLY_COMPLETED" :
            case "FAILED" :
                if(status == "FAILED")IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "The mount detection process failed.", "", OutputMessageType.Toast));
                await resetMountingsData(store.getState().detectedData.mountingsData);
                clearInterval(runLoop); 
                SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Enable_Highlight_Mount);
                break;
        }
    }, 10000);
    setTimeout(() => clearInterval(runLoop), 1800000);
    store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
    return;

}

export const runMountDetectionWithK8s = async (inputData:any) => {
    await IModelApp.tools.run(HighlightMountTool.toolId, {}, false, ["None"]);
    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Mount detection process has started & may take several minutes to complete. You will be notified via email when it is done.", "", OutputMessageType.Toast));
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Disable_Highlight_Mount);
    
    let requestDataObject = {
        detector: "mountIdentificationPly_v1_0", 
        projectSAStoken: inputData.projectSAStoken,
        contextId: ConfigManager.projectId,
        args: {
            structureData: inputData.structureData,  // Tower Structure Data ( Output from ‘/v1.0/{context ID}/Site/Structure’ GET API)
            mountsData: inputData.mountsData, // Mount’s Data ( Output from ‘/v1.1/{context ID}/MountingsData’ GET API)
            eq_r_pos: inputData.equipmentsPosition, // Equipment’s Data ( Output from ‘/v1.0/{context ID}/Equipment/All/Position’ GET API)
            updateMTDB: true,
            sendNotification: true, // Optional – Default false
            projectName: inputData.projectName,
            scale_factor: 1.0
        }
    }

    let sendRequestData = JSON.stringify(requestDataObject)
    
    let userRequst: UserRequest = {
        projectId: ConfigManager.projectId,
        source: UserRequestSource.K8SDetectionWorkflows,
        requestData: sendRequestData,
        requestApp: UserRequestApp.PORTAL
    }
    
    let userRequestId = await DatabaseApiClient.addUserRequest(userRequst);
    if (userRequestId != "") await DatabaseApiClient.postUserRequestProcessMessage(userRequestId, UserRequestSource.K8SDetectionWorkflows);
    
    let runLoop: any;
    runLoop = setInterval(async ()=>{
        const response = await DatabaseApiClient.UserRequestById(userRequestId);
        const status = getUserRequestStatus(response.userRequestById.status)
        switch (status){
            case "COMPLETED": {
                clearInterval(runLoop);
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, "The mount detection process completed successfully. Updated Mounts Highlighted.", "", OutputMessageType.Toast));
                const mountsData = await EquipmentClient.getMountingsDataJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken!)
                store.dispatch(setMountingsData(mountsData));
                await resetMountingsData(mountsData);
                SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Enable_Highlight_Mount);
                break;
            }
            case "PARTIALLY_COMPLETED" :
            case "FAILED" :
                if(status == "FAILED")IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "The mount detection process failed.", "", OutputMessageType.Toast));
                await resetMountingsData(store.getState().detectedData.mountingsData);
                clearInterval(runLoop); 
                SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Enable_Highlight_Mount);
                break;
        }
    }, 10000);
    setTimeout(() => clearInterval(runLoop), 1800000);
    store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
    return;

}

export const getUserRequestStatus = (statusCode: number) => {
    let status = "QUEUED";
    switch (statusCode) {
        case 1:
            status = "QUEUED";
            break;
        case 2:
            status = "IN_PROGRESS";
            break;
        case 3:
            status = "COMPLETED";
            break;
        case 4:
            status = "PARTIALLY_COMPLETED";
            break;
        case 5:
            status = "FAILED";
            break;
    }

    return status;
}

export const resetMountingsData = async(mountsData: any) => {
    await IModelApp.tools.run(HighlightMountTool.toolId, mountsData, true, ["All"], false);
    
    let md = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
    const theNameObjectIds = md.objectIdMap;
    const built3ObjectMaps = store.getState().detectedData.built3DObjectIdMaps;
    let newIdVals: any[] = [];
    newIdVals = built3ObjectMaps.idValues.filter((e:[string, string])=>(!e[1].match(/mountGeom/)));
    Object.entries([...theNameObjectIds]).forEach((e)=>{if(!e[1][1].match(/_edit/))newIdVals.push(e[1]);})
    const newValIds: any[] = newIdVals.map((e: any[])=>[...e].reverse())
    store.dispatch(updateBuilt3DObjectIdsMap({idValues: newIdVals, valueIds: newValIds}));
}

export const executeCreateEquipment = async ()=>{
    const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;
    const ED: EquipmentData = decorator.createNewEquipment(EquipmentType.Antenna);
    Place3DObjectApi.objectProperties = {mode: PlacementMode.Create, properties: ED};
    decorator.createGeometry(ED, CreateGeometryMode.New);
    ShapeDecorator.selectedEquipment.creating=true;
    ShapeDecorator.selectedEquipment.displayName=ED.DisplayName;
    const shape = decorator.shapes.find(e=>e.modelData?.DisplayName==ED.DisplayName);
    resetObjectIds(decorator);
    
    const iModelConnection = UiFramework.getIModelConnection();
    iModelConnection?.selectionSet.emptyAll();
    iModelConnection?.selectionSet.add(shape?.transientId as Id64String);

    const shapeIndex = decorator!.shapes.findIndex((item) => item.modelData?.DisplayName === ED.DisplayName);
    if(!ModifyHandleDecoration._decorator && shapeIndex !== -1){
        // ModifyHandleDecoration.clear();
        ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, decorator.shapes[shapeIndex].transientId, shape?.modelData?.DisplayName!, shapeIndex, decorator);
    }
    if (ModifyHandleDecoration._decorator && decorator.shapes[shapeIndex].modelData?.shapeId == null) {
        ModifyHandleDecoration._decorator._shape = decorator.shapes[shapeIndex].modelData?.geometryEntry?.geometry as Box;
        ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
        ModifyHandleDecoration._decorator._shapeName = ED.DisplayName!;
        ModifyHandleDecoration._decorator.createClipShapeControls();
    } 
    // else ModifyHandleDecoration.clear();


    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_MARK_EQUIPMENT_MANUALLY");
    IModelApp.tools.run(PlacementTool.toolId, PlacementMode.Create, DetectedGeometryType.Equipment, (e: string)=>{
        switch (e) {
          case "Reset":
            let shapeIndex = decorator.shapes.findIndex((shape) => shape.modelData!.DisplayName === ShapeDecorator.selectedEquipment.displayName);
            if(shapeIndex>-1){
                let shapeDN = decorator.shapes[shapeIndex].modelData!.DisplayName;
                decorator.deleteCurrentShape(shapeDN);
                decorator.resetSelectedEquipment();
                ShapeDecorator.selectedEquipment.creating=false;
                ShapeDecorator.selectedEquipment.created=false;
                
                SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.Nothing_Selected);          
                if(ModifyHandleDecoration._decorator)ModifyHandleDecoration.clear();
            }
            break;
          case "Success":
            // ShapeDecorator.selectedEquipment.creating=false;
            ShapeDecorator.selectedEquipment.created=true;
            UiItemsManager.register(new EquipmentEditToolUiProvider(FRONTSTAGE_SETTINGS_NAMESPACE));
            const equipWidget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("EquipmentEditWidget");
            if(equipWidget?.activeState == WidgetState.Open){
                equipWidget?.setWidgetState(WidgetState.Closed);
                equipWidget?.setWidgetState(WidgetState.Open);
            } else {
                equipWidget?.setWidgetState(WidgetState.Open);
            }
            break;
          default:
            break;
        }
    })

    
}


export const executeCloneEquipment = async ()=>{
    const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;

    let shapeIndex = decorator.shapes.findIndex((shape) => shape.modelData!.DisplayName === ShapeDecorator.selectedEquipment.displayName);
    const equipmentJson = decorator.shapes[shapeIndex];
    ShapeDecorator.selectedEquipment.creating = true;
    // let shapeDN = equipmentJson.modelData!.DisplayName;
    // let equipmentChanged: boolean = false;
    // if(ShapeDecorator.selectedEquipment.creating && equipmentJson.modelData?.Equipment_Type != equipmentJson.type){
    //   equipmentJson.modelData = decorator.createNewEquipment(equipmentJson.type);
    //   Place3DObjectApi.objectProperties = {mode: PlacementMode.Clone, properties: equipmentJson.modelData};
    //   equipmentChanged = true;
    // }
    let theType: EquipmentType = EquipmentType.Antenna;
    switch (equipmentJson.type) {
      case EquipmentType.RRU:
        theType = EquipmentType.RRU;
        break;
      case EquipmentType.Microwave:
        theType = EquipmentType.Microwave;
        break;
      case EquipmentType.SQUID:
        theType = EquipmentType.SQUID;
        break;
    
      default:
        break;
    }
    let theClonedData: EquipmentData = decorator.createNewEquipment(theType);
    theClonedData = {...equipmentJson.modelData!, DisplayName: theClonedData.DisplayName, Equipment_Name: theClonedData.Equipment_Name}
    // theClonedData.Azimuth = equipmentJson.modelData?.Azimuth!;
    // theClonedData.Roll = equipmentJson.modelData?.Roll!;
    // theClonedData.Tilt = equipmentJson.modelData?.Tilt!;
    // theClonedData.Height = equipmentJson.modelData?.Height!;
    // theClonedData.Width = equipmentJson.modelData?.Width!;
    // theClonedData.Thickness = equipmentJson.modelData?.Thickness!;
    // theClonedData.x_position = equipmentJson.modelData?.x_position!;
    // theClonedData.y_position = equipmentJson.modelData?.y_position!;
    // theClonedData.z_position = equipmentJson.modelData?.z_position!;
    // theClonedData.shapeId = equipmentJson.modelData?.shapeId!;
      
    // const iModel = UiFramework.getIModelConnection();
    // const cart = iModel!.spatialToCartographicFromEcef(location);
    // const m = DecoratorHelper.convertWGS2UTM(cart);
    // location.x = m[0];
    // location.y = m[1];
    // location.z = cart.height;

    // theClonedData.x_position = location.x;
    // theClonedData.y_position = location.y;
    // theClonedData.z_position = location.z;

    decorator.createGeometry(theClonedData, CreateGeometryMode.New);
    const shape = decorator.shapes.find(e=>e.modelData?.DisplayName==theClonedData.DisplayName);
    ShapeDecorator.selectedEquipment.displayName = theClonedData.DisplayName;
    ShapeDecorator.selectedEquipment.transId = shape?.transientId!;

    resetObjectIds(decorator);

    const iModelConnection = UiFramework.getIModelConnection();
    iModelConnection?.selectionSet.emptyAll();
    iModelConnection?.selectionSet.add(shape?.transientId as Id64String);
  
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);

    Place3DObjectApi.objectProperties = {mode: PlacementMode.Clone, properties: decorator.shapes.find(e=>e.modelData?.DisplayName == theClonedData.DisplayName)?.modelData!};
        
    TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_MARK_EQUIPMENT_MANUALLY");
    // void IModelApp.tools.run(PlacementTool.toolId, PlacementMode.Clone)
    
    // UiItemsManager.register(new EquipmentEditToolUiProvider(FRONTSTAGE_SETTINGS_NAMESPACE));
    // UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("EquipmentEditWidget")?.setWidgetState(WidgetState.Open);
    IModelApp.tools.run(PlacementTool.toolId, PlacementMode.Clone, DetectedGeometryType.Equipment, (e: string)=>{
        switch (e) {
          case "Reset":
            let shapeIndex = decorator.shapes.findIndex((shape) => shape.modelData!.DisplayName === ShapeDecorator.selectedEquipment.displayName);
            if(shapeIndex>-1){
                let shapeDN = decorator.shapes[shapeIndex].modelData!.DisplayName;
                decorator.deleteCurrentShape(shapeDN);
                decorator.resetSelectedEquipment();
                ShapeDecorator.selectedEquipment.creating=false;
                ShapeDecorator.selectedEquipment.created=false;
                
                SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.Nothing_Selected);          
                if(ModifyHandleDecoration._decorator)ModifyHandleDecoration.clear();
            }
            break;
          case "Success":
            // ShapeDecorator.selectedEquipment.creating=false;
            ShapeDecorator.selectedEquipment.created=true;
            UiItemsManager.register(new EquipmentEditToolUiProvider(FRONTSTAGE_SETTINGS_NAMESPACE));
            const equipWidget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("EquipmentEditWidget");
            if(equipWidget?.activeState == WidgetState.Open){
                equipWidget?.setWidgetState(WidgetState.Closed);
                equipWidget?.setWidgetState(WidgetState.Open);
            } else {
                equipWidget?.setWidgetState(WidgetState.Open);
            }
            break;
          default:
            break;
        }
    })

}

export const eventExecutionHandler = async ({title, component, onClose, mode}) => {
    if(mode === "Add"){
        store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
        store.dispatch(DTVActions.setEditModeFlag(false));
    }
    if(UiFramework.dialogs.modeless.dialogs.filter(e=>e.id == "Operator Elevation Mapping").length)UiFramework.dialogs.modeless.close("Operator Elevation Mapping");
    else 
    UiFramework.dialogs.modeless.open(
        //   <VerticalTabs comp={selectedMenu}/>,
        <EditDialogComponent
            //   title="Frontstage Introduction"
            isOpen={true}
            title={title}
            targetComponent={component}
            onClose={onClose}
            message={["This sample serves as a basic introduction to frontstages.",
                "It contains four miscellaneous frontstage examples.",
                "Close this dialog and use the button in the top left corner to switch between each frontstage.",
            ].join(" ")} 
            modalId={""}
        />,
        title
    );
}


export const getHorizontalToolbarItems = (stageId, toolbarUsage: ToolbarUsage, toolbarOrientation: ToolbarOrientation, buttonStates: customToolbarButtonsInterface) => {
    const toolbarItems: ToolbarItem[] = [];
    const currentState = store.getState();
    let editDisabled=true;

    if (stageId === "DigitalTwinViewerStage") {
        // Toolbars will appear in the top left
        if (toolbarUsage === ToolbarUsage.ContentManipulation) {
            if (toolbarOrientation === ToolbarOrientation.Horizontal) {
                // toolbarItems.push(...ToolbarHelper.createToolbarItemsFromItemDefs([groupItem(), customItem()], 100));
                const enableBackgroundMap = ToolbarItemUtilities.createActionButton("enable-map", 100, IconSpecUtilities.createWebComponentIconSpec("map.svg"), "Enable Background Map",
                (): void => {
                    // If you want to know more about handling ViewFlags, checkout the "View Attributes" sample (https://www.itwinjs.org/sandboxes/iTwinPlatform/View%20Attributes)
                    const vp = IModelApp.viewManager.selectedView;
                    if (vp) {
                    vp.viewFlags = vp.viewFlags.with("backgroundMap", true);
                    }
                });
                const newRadLevel = ToolbarItemUtilities.createActionButton("addNewRad", 100, "icon-2d", "Add new RAD Level",
                (): void => {
                    // If you want to know more about handling ViewFlags, checkout the "View Attributes" sample (https://www.itwinjs.org/sandboxes/iTwinPlatform/View%20Attributes)
                    const vp = IModelApp.viewManager.selectedView;
                    if (vp) {
                    vp.viewFlags = vp.viewFlags.with("backgroundMap", true);
                    }
                });
                const createGratingFromPoints = ToolbarItemUtilities.createActionButton("CreatePoly", 110, "icon-model-isolate", "Create Grating From Click Points",
                (): void => {
                    // If you want to know more about handling ViewFlags, checkout the "View Attributes" sample (https://www.itwinjs.org/sandboxes/iTwinPlatform/View%20Attributes)
                    const vp = IModelApp.viewManager.selectedView;
                    if (vp) {
                    vp.viewFlags = vp.viewFlags.with("backgroundMap", true);
                    }
                });

                const addBra = ToolbarItemUtilities.createActionButton("AddBracingTool", 110, "icon-annotation-add", "Add Bracing",
                (): void => {
                    // If you want to know more about handling ViewFlags, checkout the "View Attributes" sample (https://www.itwinjs.org/sandboxes/iTwinPlatform/View%20Attributes)
                    const vp = IModelApp.viewManager.selectedView;
                    if (vp) {
                    vp.viewFlags = vp.viewFlags.with("backgroundMap", true);
                    }
                });

                const highlightAnnotation = ToolbarItemUtilities.createActionButton("CreatePinAnnotation", 110, "icon-annotation-add", "Create Pin Annotation",
                (): void => {
                    // If you want to know more about handling ViewFlags, checkout the "View Attributes" sample (https://www.itwinjs.org/sandboxes/iTwinPlatform/View%20Attributes)
                    const vp = IModelApp.viewManager.selectedView;
                    if (vp) {
                    vp.viewFlags = vp.viewFlags.with("backgroundMap", true);
                    }
                });

                const disableBackgroundMap = ToolbarItemUtilities.createActionButton("disable-map", 110, IconSpecUtilities.createWebComponentIconSpec("remove.svg"), "Disable Background Map",
                (): void => {
                    const vp = IModelApp.viewManager.selectedView;
                    if (vp) {
                    vp.viewFlags = vp.viewFlags.with("backgroundMap", false);
                    }
                });
                const downRepCom = ToolbarItemUtilities.createActionButton("icon-reports", 110, "icon-reports", "Download Planning Report",
                (): void => {
                    // if (this.state.planRepExist)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Please wait while we are checking for the planning report...", "", OutputMessageType.Toast));
                    // const url = ConfigManager.INsiteImageUrl.replace("img?", "planningReport?");
                    const anc = document.createElement("a");
                    const iFr = document.createElement("iframe");
                    iFr.id = "prIfr";
                    iFr.name = "ifr";
                    iFr.style.display = "none";
                    // anc.href = url;
                    anc.target = "ifr";
                    document.body.append(iFr);
                    document.body.append(anc);
                    document.getElementById("prIfr")!.onload = () => {
                    // if (this.state.planRepExist)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "The Planning Report does not exist for this site.", "", OutputMessageType.Toast));
                    // this.setState({planRepExist: false});
                    return;
                    };
                    anc.click();
                });

                const hiLitEquip = HighlightEquipment();
                const hiLitShape = HighlightShape();
                const hiLitFeeders = HighlightFeeders();
                const hiLitMount = HighlightMount(buttonStates);
                const hiLitTower = HighlightTower();
                const hiLitBracings = HighlightBracings();
                const hiLitGrating = HighlightGratings();
                const hiLitFeedline = HighlightFeedline();
                const hiLitPinAnnotation = HighlightPinAnnotations();
                const hiLitLadder = HighlightLadder();
                const hilitAllObjects = HighlightAllDetectedObjects()


                const downRepCommand = new ToolItemDef({
                execute: async () => {
                    // if (this.state.planRepExist)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Please wait while we are checking for the planning report...", "", OutputMessageType.Toast));
                    // const url = ConfigManager.INsiteImageUrl.replace("img?", "planningReport?");
                    const anc = document.createElement("a");
                    const iFr = document.createElement("iframe");
                    iFr.id = "prIfr";
                    iFr.name = "ifr";
                    iFr.style.display = "none";
                    // anc.href = url;
                    anc.target = "ifr";
                    document.body.append(iFr);
                    document.body.append(anc);
                    document.getElementById("prIfr")!.onload = () => {
                    // if (this.state.planRepExist)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "The Planning Report does not exist for this site.", "", OutputMessageType.Toast));
                    // this.setState({planRepExist: false});
                    return;
                    };
                    anc.click();
                },
                toolId: "Planning report",
                iconSpec: "icon-reports",
                labelKey: "Planning Report",
                tooltipKey: "",
                // isDisabled: !this.state.planRepExist,
                });

                const editActive = currentState.dtvState.applicationState.isEditModeActive;
                const selObjName = currentState.detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
                // const editBtn = dtToolbar;
                const editBtn = new ToolItemDef({
                    execute: editExecution,
                    toolId: "Edit Object",
                    isActive: true,
                    iconSpec: <SvgEdit />,
                    label: new ConditionalStringValue(() => `Edit ${buttonStates.edit.label}`, [SyncUiEventIds.RM_Equipment_Selected, SyncUiEventIds.RM_Mount_Selected, SyncUiEventIds.RM_Tower_Selected, SyncUiEventIds.RM_Equipment_UnSelected, SyncUiEventIds.RM_Bracing_Selected, SyncUiEventIds.RM_Grating_Selected, SyncUiEventIds.DM_Equipment_Selected, SyncUiEventIds.RM_Feedline_Selected, SyncUiEventIds.RM_Feedline_UnSelected,SyncUiEventIds.RM_Ladder_Selected,SyncUiEventIds.RM_Ladder_UnSelected]),
                    isHidden: new ConditionalBooleanValue(() => buttonStates.edit.disabled, [SyncUiEventIds.RM_Equipment_Selected, SyncUiEventIds.RM_Mount_Selected, SyncUiEventIds.RM_Tower_Selected, SyncUiEventIds.RM_Equipment_UnSelected, SyncUiEventIds.RM_Bracing_Selected, SyncUiEventIds.RM_Grating_Selected, SyncUiEventIds.DM_Equipment_Selected, SyncUiEventIds.RM_Feedline_Selected, SyncUiEventIds.RM_Feedline_UnSelected,SyncUiEventIds.RM_Ladder_Selected,SyncUiEventIds.RM_Ladder_UnSelected]),
                    isDisabled: new ConditionalBooleanValue(() => buttonStates.edit.disabled, [SyncUiEventIds.RM_Equipment_Selected, SyncUiEventIds.RM_Mount_Selected, SyncUiEventIds.RM_Tower_Selected, SyncUiEventIds.RM_Equipment_UnSelected, SyncUiEventIds.RM_Bracing_Selected, SyncUiEventIds.RM_Grating_Selected, SyncUiEventIds.DM_Equipment_Selected, SyncUiEventIds.RM_Feedline_Selected, SyncUiEventIds.RM_Feedline_UnSelected,SyncUiEventIds.RM_Ladder_Selected,SyncUiEventIds.RM_Ladder_UnSelected])
                });

                const addNewFeedline = ToolbarItemUtilities.createActionButton(
                    "PlaceNewFeedline",
                    1000,
                    "icon-gesture-one-finger-drag",
                    new ConditionalStringValue(() => buttonStates.addNewFeedlineButton.disabled ? buttonStates.addNewFeedlineButton.label+" Feedline" : "Add New Feedline", [SyncUiEventIds.RM_Feedline_Highlighted, SyncUiEventIds.RM_Feedline_UnHighlighted]),
                    async ()=>{
                        TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_MARK_EQUIPMENT_MANUALLY");
                        // SyncUiEventDispatcher.dispatchSyncUiEvent('antenna');
                        IModelApp.tools.run(AddFeedlineTool.toolId);
                    },
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewFeedlineButton.disabled, [SyncUiEventIds.RM_Feedline_Highlighted, SyncUiEventIds.RM_Feedline_UnHighlighted])}
                );

                const placeNewEquipment = ToolbarItemUtilities.createActionButton(
                    "PlaceNewEquipment",
                    1000,
                    "icon-gesture-one-finger-drag",
                    new ConditionalStringValue(() => buttonStates.addNewEquipmentButton.disabled ? buttonStates.addNewEquipmentButton.label+" Equipment" : "Add New Equipment", [SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted]),
                    executeCreateEquipment,
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewEquipmentButton.disabled, [SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted])}
                );


                const placeNewAntenna = ToolbarItemUtilities.createActionButton(
                    "PlaceNewAntenna",
                    1000,
                    "icon-gesture-one-finger-drag",
                    new ConditionalStringValue(() => buttonStates.addNewEquipmentButton.disabled ? buttonStates.addNewEquipmentButton.label+" Antenna" : "Place New Antenna", [SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted]),
                    async ()=>{
                        TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_MARK_EQUIPMENT_MANUALLY");
                        // SyncUiEventDispatcher.dispatchSyncUiEvent('antenna');
                        IModelApp.tools.run(AddBoundingBoxTool.toolId, EquipmentType.Antenna);
                    },
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewEquipmentButton.disabled, [SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted])}
                );

                const placeNewRRU = ToolbarItemUtilities.createActionButton(
                    "PlaceNewRRU",
                    2000,
                    "icon-rectangle",
                    new ConditionalStringValue(() => buttonStates.addNewEquipmentButton.disabled ? buttonStates.addNewEquipmentButton.label+" RRU" : "Place New Remote Radio Unit", [SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted]),
                    async ()=>{
                        IModelApp.tools.run(AddBoundingBoxTool.toolId, EquipmentType.RRU);
                    },
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewEquipmentButton.disabled, [SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted])}
                );

                const placeNewMW = ToolbarItemUtilities.createActionButton(
                    "PlaceNewMW",
                    2000,
                    "icon-snaps-center",
                    new ConditionalStringValue(() => buttonStates.addNewEquipmentButton.disabled ? buttonStates.addNewEquipmentButton.label+" Microwave" : "Place New Microwave Dish", [SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted]),
                    async ()=>{
                        // SyncUiEventDispatcher.dispatchSyncUiEvent('microwave');
                        IModelApp.tools.run(AddBoundingBoxTool.toolId, EquipmentType.Microwave);
                    },
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewEquipmentButton.disabled, [SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted])}
                );

                const addNewGrating = ToolbarItemUtilities.createActionButton(
                    "CreatePoly",
                    2000,
                    "icon-model-isolate",
                    new ConditionalStringValue(() => buttonStates.addNewGratingButton.disabled ? buttonStates.addNewGratingButton.label : "Add New Grating", [SyncUiEventIds.RM_Grating_Highlighted, SyncUiEventIds.RM_Grating_UnHighlighted]),
                    async ()=>{
                        eventExecutionHandler({mode: "Add", title: "Add New Grating", component: targetComponentList.RM_GratingEditor, onClose: () => {UiFramework.dialogs.modeless.close("Add New Grating")}
                        });                        
                        // SyncUiEventDispatcher.dispatchSyncUiEvent('microwave');
                        // IModelApp.tools.run(AddBoundingBoxTool.toolId, EquipmentType.Microwave);
                  

                        IModelApp.tools.run(AddEditPolygonShapeTool.toolId);
                    },
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewGratingButton.disabled, [SyncUiEventIds.RM_Grating_Highlighted, SyncUiEventIds.RM_Grating_UnHighlighted])}
                );

                const addNewBracing = ToolbarItemUtilities.createActionButton(
                    "AddBracingTool",
                    2000,
                    "icon-browse",
                    new ConditionalStringValue(() => buttonStates.addNewBracingButton.disabled ? buttonStates.addNewBracingButton.label : "Add New Bracing", [SyncUiEventIds.RM_Bracing_Highlighted, SyncUiEventIds.RM_Bracing_UnHighlighted]),
                    async ()=>{
                        AddEditBracingTool.isBracingEditable = false;
                        eventExecutionHandler({mode: "Add", title: "Add New Bracing", component: targetComponentList.RM_BracingEditor, onClose: () => {UiFramework.dialogs.modeless.close("Add New Bracing")}
                        });                        
                        // SyncUiEventDispatcher.dispatchSyncUiEvent('microwave');
                        // IModelApp.tools.run(AddBoundingBoxTool.toolId, EquipmentType.Microwave);
                    },
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewBracingButton.disabled, [SyncUiEventIds.RM_Bracing_Highlighted, SyncUiEventIds.RM_Bracing_UnHighlighted])}
                );

                const addNewAnnotation = ToolbarItemUtilities.createActionButton(
                    "CreatePinAnnotation",
                    2000,
                    "icon-annotation-add",
                    new ConditionalStringValue(() => buttonStates.addNewAnnotationButton.disabled ? buttonStates.addNewAnnotationButton.label : "Create New Annotation", [SyncUiEventIds.Annotations_Highlighted, SyncUiEventIds.Annotations_UnHighlighted]),
                    async ()=>{
                        // SyncUiEventDispatcher.dispatchSyncUiEvent('microwave');
                        // IModelApp.tools.run(AddBoundingBoxTool.toolId, EquipmentType.Microwave);
                        UiFramework.content.dialogs.close("PinPropertyPopup");
                        IModelApp.tools.run(AddPinAnnotationTool.toolId);
                 
                        await UiFramework.frontstages.setActiveFrontstage("sandbox:two-viewports-frontstage");  
                    },
                    // {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewAnnotationButton.disabled || store.getState().dtvState.applicationState.licenseStates.inspectionLicense.licenseEnabled, [SyncUiEventIds.Annotations_Highlighted, SyncUiEventIds.Annotations_UnHighlighted, SyncUiEventIds.Update_License_Features])}
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewAnnotationButton.disabled, [SyncUiEventIds.Annotations_Highlighted, SyncUiEventIds.Annotations_UnHighlighted, SyncUiEventIds.Update_License_Features])}
                );
                              
                const addNewLadder = ToolbarItemUtilities.createActionButton(
                    "addNewLadderButton",
                    2000,
                    IconSpecUtilities.createWebComponentIconSpec(ladder),
                    new ConditionalStringValue(() => buttonStates.addNewLadderButton.disabled ? buttonStates.addNewLadderButton.label : "Add New Ladder", [SyncUiEventIds.RM_Ladder_Highlighted, SyncUiEventIds.RM_Ladder_UnHighlighted]),
                    async ()=>{
                        AddLadderTool.getNewLadderData();
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, `Please select 2 on 3D model as the width of the ladder.`, "", OutputMessageType.Toast));
                    },
                    {isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewLadderButton.disabled , [SyncUiEventIds.RM_Ladder_Highlighted, SyncUiEventIds.RM_Ladder_UnHighlighted])}
                );

                const showiModel = new ToolItemDef({
                    execute: async () => {
                        // UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("tree-widget-react:trees")?.setWidgetState(WidgetState.Open);
                        const frontStages = UiFramework.frontstages;
                        if(frontStages?.activeFrontstageDef?.findWidgetDef("DataVizControl")?.state != WidgetState.Open)frontStages?.activeFrontstageDef?.findWidgetDef("DataVizControl")?.setWidgetState(WidgetState.Open);
                        if(frontStages?.activeFrontstageDef?.findWidgetDef("tree-widget-react:trees")?.state != WidgetState.Open)frontStages?.activeFrontstageDef?.findWidgetDef("tree-widget-react:trees")?.setWidgetState(WidgetState.Open);
                        else {
                            let stageDef: FrontstageDef | undefined = await frontStages.getFrontstageDef("DigitalTwinViewerStage");
                            stageDef?.rightPanel && (stageDef.rightPanel.panelState = StagePanelState.Minimized);
                            stageDef?.leftPanel && (stageDef.leftPanel.panelState = StagePanelState.Minimized);
                        }
                    },
                    toolId: "Show iModel",
                    iconSpec: <SvgModel />,
                    // labelKey: "Show/Hide Design Model",
                    label: new ConditionalStringValue(() => buttonStates.hasDesignModel.label, [SyncUiEventIds.Has_Design_Model]),
                    tooltipKey: "",
                    isDisabled: new ConditionalBooleanValue(() => buttonStates.hasDesignModel.disabled, [SyncUiEventIds.Has_Design_Model])
                    // isDisabled: new ConditionalBooleanValue(() => store.getState().dtvState.applicationState.hasDesignModel, [SyncUiEventIds.Has_Design_Model])
                });
                
                const showRealityModel = new ToolItemDef({
                    execute: async () => {
                        const frontStages = UiFramework.frontstages;
                        if(frontStages?.activeFrontstageDef?.findWidgetDef("RealityDataWidget")?.state != WidgetState.Open)frontStages?.activeFrontstageDef?.findWidgetDef("RealityDataWidget")?.setWidgetState(WidgetState.Open);
                        else {
                            let stageDef: FrontstageDef | undefined = await frontStages.getFrontstageDef("DigitalTwinViewerStage");
                            stageDef?.rightPanel && (stageDef.rightPanel.panelState = StagePanelState.Minimized);
                            stageDef?.leftPanel && (stageDef.leftPanel.panelState = StagePanelState.Minimized);
                        }
                    },
                    toolId: "ShowRealityModel",
                    // iconSpec: "icon-3d-photo",
                    iconSpec: IconSpecUtilities.createWebComponentIconSpec(realityDataSvg),
                    labelKey: "Show/Hide Reality Model",
                    tooltipKey: "",
                    isActive: true,
                    badgeType: BadgeType.TechnicalPreview,
                    isDisabled: new ConditionalBooleanValue(() => buttonStates.hasRealityModel.disabled, [SyncUiEventIds.Has_Reality_Model])
                });

                const addNewRad = new ToolItemDef({
                    execute: executeRADFunction,
                    toolId: "add-RAD-tool",
                    // iconSpec: "icon-3d-photo",
                    iconSpec: "icon-section-shape",
                    labelKey: "Add new RAD Level",
                    tooltipKey: "",
                    badgeType: BadgeType.TechnicalPreview,
                    isDisabled: new ConditionalBooleanValue(() => buttonStates.radLevel.disabled, [SyncUiEventIds.Implement_Feature_Flag, SyncUiEventIds.Apply_Feature_Flag,SyncUiEventIds.Not_Show_All_Detected_Objects])
                });

                const toolsArr: ToolItemDef[] = [CoreTools.selectElementCommand, editBtn, showiModel, showRealityModel, addNewRad];
                // const toolsArr = [CoreTools.selectElementCommand, showiModel, showRealityModel];

                // toolbarItems.push(ToolbarItemUtilities.createGroupButton("RAD-group", 2000,  "icon-section-shape", "Add RAD Levels", [newRadLevel, createGratingFromPoints, addBra, highlightAnnotation], { badgeType: BadgeType.TechnicalPreview }) );
                toolbarItems.push(...ToolbarHelper.createToolbarItemsFromItemDefs(toolsArr, 1000));
                toolbarItems.push(ToolbarItemUtilities.createGroupButton("map-group", 2000, "icon-3d", "Highlight Detected Objects", [hiLitShape, hiLitMount, hiLitBracings, hiLitTower, hiLitGrating, hiLitFeeders, hiLitPinAnnotation, hiLitLadder,hilitAllObjects], { badgeType: BadgeType.TechnicalPreview }));
                toolbarItems.push(ToolbarItemUtilities.createGroupButton(
                    "Turn-on-hightlight-equipment", 
                    3000, 
                    "icon-edit-all", 
                    new ConditionalStringValue(() => buttonStates.addNewMenu.disabled ? buttonStates.addNewMenu.label : "Add New Elements", [SyncUiEventIds.Enable_Add_Object, SyncUiEventIds.Disable_Add_Object, SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted, SyncUiEventIds.RM_Feedline_Highlighted, SyncUiEventIds.RM_Feedline_UnHighlighted, SyncUiEventIds.Annotations_Highlighted, SyncUiEventIds.Annotations_UnHighlighted, SyncUiEventIds.RM_Ladder_Highlighted, SyncUiEventIds.RM_Ladder_UnHighlighted,SyncUiEventIds.Not_Show_All_Detected_Objects]), 
                    // [placeNewEquipment, placeNewAntenna, placeNewRRU, placeNewMW, addNewGrating, addNewBracing, addNewAnnotation, addNewFeedline,addNewLadder,placementTool], 
                    [placeNewEquipment, addNewGrating, addNewBracing, addNewAnnotation, addNewFeedline,addNewLadder], 
                    { badgeType: BadgeType.TechnicalPreview, isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewMenu.disabled, [SyncUiEventIds.Enable_Add_Object, SyncUiEventIds.Disable_Add_Object, SyncUiEventIds.RM_Equipment_Highlighted, SyncUiEventIds.RM_Equipment_UnHighlighted, SyncUiEventIds.RM_Feedline_Highlighted, SyncUiEventIds.RM_Feedline_UnHighlighted, SyncUiEventIds.Annotations_Highlighted, SyncUiEventIds.Annotations_UnHighlighted, SyncUiEventIds.RM_Ladder_Highlighted, SyncUiEventIds.RM_Ladder_UnHighlighted,SyncUiEventIds.Not_Show_All_Detected_Objects])}
                    // { badgeType: BadgeType.TechnicalPreview, isDisabled: new ConditionalBooleanValue(() => buttonStates.addNewMenu.disabled, [SyncUiEventIds.Enable_Add_Object, SyncUiEventIds.Disable_Add_Object])}
                ));
                
                const deleteObject = new ToolItemDef({
                    execute: async () => {
                        
                        let selectedObject = store.getState().detectedData.selectedObjectInformation;
                        if(selectedObject && selectedObject.objectProperties.objectCategoryType == ObjectCategoryTypes.Mount_DM) UiFramework.content.dialogs.open(
                                <DeleteMount imodel = {UiFramework.getIModelConnection()}/>,
                                "deleteObject"
                            )
                        else if (AnnotationDecorator.selectedMarkerJson != undefined && AnnotationDecorator.selectedMarkerJson.tagId != undefined){
                            let tdel: boolean = await AddPinAnnotationTool.deleteSelectedTag();
                            if (tdel == true) {
                                let defectsdec = new AnnotationDecorator;
                                await  defectsdec.getPinsAndDefect(true)
                                // The complete list refreshed on delete.
                                let token :any = store.getState().auth.accessTokenStatePrivateAPI.accessToken;
                                let data = await PinTagClient.getPinTags(token);
                                AnnotationDecorator.selectedMarkerJson = { fullList: true, data: data };
                                // AnnotationDecorator.selectedMarkerJson = undefined;
                                store.dispatch(DTVActions.setShowPinTags(false));
                            } else {
                                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error In Selection, Please Try Again", "", OutputMessageType.Toast));
                            }
                        }else if(selectedObject && selectedObject.heading == "Bracing Information"){
                            const bDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
                            if (bDecor != undefined && bDecor.selectedBracings != undefined && bDecor.selectedBracings.modelData != undefined){
                                AddEditBracingTool.deleteBracingFromData();
                                SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Nothing_Selected);                            
                            }
                        }else if(selectedObject && selectedObject.heading == "Grating Information"){
                            // PolygonTransformWidget.deleteSelectedPolygon();
                            UiFramework.content.dialogs.open(<ConfirmationModal selectedObject={selectedObject} title={`Grating ${selectedObject.objectProperties.selectedObjectNST.name.split('@')[0].split('_').slice(1).join(' ')}`} message={""}/>, "Delete Confirmation")
                            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Nothing_Selected);                            
                        }else if(selectedObject && selectedObject.heading == "Ladder Information"){
                            // PolygonTransformWidget.deleteSelectedPolygon();
                            UiFramework.content.dialogs.open(<ConfirmationModal selectedObject={selectedObject} title={`Selected ladder`} message={""}/>, "Delete Confirmation")
                        }else if(selectedObject && selectedObject.heading == "Feedline Information"){
                            // PolygonTransformWidget.deleteSelectedPolygon();
                            UiFramework.content.dialogs.open(<ConfirmationModal selectedObject={selectedObject} title={`Feedline ${selectedObject.objectProperties.selectedObjectNST.name}`} message={""}/>, "Delete Confirmation")
                            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Nothing_Selected);                            
                        }else if(selectedObject && selectedObject.heading == "Defect Information"){
                            const {criticality, defectiveStructure} = AnnotationDecorator.selectedMarkerJson;

                            UiFramework.content.dialogs.open(<ConfirmationModal selectedObject={selectedObject} title={`${criticality} Defect in ${defectiveStructure}`} message={""}/>, "Delete Confirmation")
                            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Nothing_Selected);                            
                        }
                         else {
                            
                            UiFramework.content.dialogs.open(<ConfirmationModal selectedObject={selectedObject} title={selectedObject.objectProperties.selectedObjectNST.displayName ?? ""} message={""}/>, "Delete Confirmation")
                        }
                        // if (this.state.planRepExist)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Please wait while we are checking for the planning report...", "", OutputMessageType.Toast));
                        // const url = ConfigManager.INsiteImageUrl.replace("img?", "planningReport?");
                    },
                    toolId: "deleteObject",
                    iconSpec: "icon-delete",
                    // labelKey: "Delete Object",
                    tooltipKey: "",
                    badgeType: BadgeType.TechnicalPreview,
                    label: new ConditionalStringValue(() => `Delete ${buttonStates.edit.label}`, [SyncUiEventIds.RM_Equipment_Selected,SyncUiEventIds.DM_Equipment_Selected, SyncUiEventIds.RM_Mount_Selected, SyncUiEventIds.DM_Mount_Selected, SyncUiEventIds.RM_Equipment_UnSelected, SyncUiEventIds.RM_Feedline_Selected, SyncUiEventIds.RM_Feedline_UnSelected, SyncUiEventIds.RM_Bracing_Selected,SyncUiEventIds.RM_Grating_Selected,SyncUiEventIds.RM_Grating_Selected,SyncUiEventIds.RM_Ladder_Selected,SyncUiEventIds.RM_Ladder_UnSelected,SyncUiEventIds.Defect_Annotation_Selected,SyncUiEventIds.Nothing_Selected]),
                    isHidden: new ConditionalBooleanValue(() => buttonStates.delete.disabled, [SyncUiEventIds.RM_Equipment_Selected,SyncUiEventIds.DM_Equipment_Selected , SyncUiEventIds.RM_Mount_Selected, SyncUiEventIds.DM_Mount_Selected, SyncUiEventIds.RM_Equipment_UnSelected, SyncUiEventIds.RM_Feedline_Selected, SyncUiEventIds.RM_Feedline_UnSelected, SyncUiEventIds.RM_Bracing_Selected,SyncUiEventIds.RM_Grating_Selected,SyncUiEventIds.RM_Grating_Selected,SyncUiEventIds.RM_Ladder_Selected,SyncUiEventIds.RM_Ladder_UnSelected,SyncUiEventIds.Defect_Annotation_Selected,SyncUiEventIds.Nothing_Selected]),
                    isDisabled: new ConditionalBooleanValue(() => buttonStates.delete.disabled, [SyncUiEventIds.RM_Equipment_Selected, SyncUiEventIds.DM_Equipment_Selected, SyncUiEventIds.DM_Mount_Selected, SyncUiEventIds.RM_Mount_Selected, SyncUiEventIds.RM_Equipment_UnSelected, SyncUiEventIds.RM_Feedline_Selected, SyncUiEventIds.RM_Feedline_UnSelected, SyncUiEventIds.RM_Bracing_Selected,SyncUiEventIds.RM_Grating_Selected,SyncUiEventIds.RM_Grating_Selected,SyncUiEventIds.RM_Ladder_Selected,SyncUiEventIds.RM_Ladder_UnSelected,SyncUiEventIds.Defect_Annotation_Selected,SyncUiEventIds.Nothing_Selected])
                });

                const cloneObject = new ToolItemDef({
                execute: executeCloneEquipment,
                // execute: async () => {
                    // if (this.state.planRepExist)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Please wait while we are checking for the planning report...", "", OutputMessageType.Toast));
                    // const url = ConfigManager.INsiteImageUrl.replace("img?", "planningReport?");
                    // IModelApp.tools.run(CloneBoundingBoxTool.toolId);
                // },
                toolId: "clone-equipment",
                iconSpec: "icon-clipboard-copy",
                labelKey: "Clone Object",
                tooltipKey: "",
                badgeType: BadgeType.TechnicalPreview,
                label: new ConditionalStringValue(() => `Clone ${buttonStates.edit.label}`, [SyncUiEventIds.RM_Equipment_Selected, SyncUiEventIds.RM_Equipment_UnSelected]),
                isHidden: new ConditionalBooleanValue(() => buttonStates.clone.disabled, [SyncUiEventIds.RM_Equipment_Selected, SyncUiEventIds.Nothing_Selected, SyncUiEventIds.RM_Equipment_UnSelected, SyncUiEventIds.RM_Mount_Selected, SyncUiEventIds.RM_Tower_Selected, SyncUiEventIds.DM_Mount_Selected, SyncUiEventIds.DM_Equipment_Selected, SyncUiEventIds.RM_Bracing_Selected, SyncUiEventIds.RM_Grating_Selected]),
                isDisabled: new ConditionalBooleanValue(() => buttonStates.clone.disabled, [SyncUiEventIds.RM_Equipment_Selected, SyncUiEventIds.Nothing_Selected, SyncUiEventIds.RM_Equipment_UnSelected, SyncUiEventIds.RM_Mount_Selected, SyncUiEventIds.RM_Tower_Selected, SyncUiEventIds.DM_Mount_Selected, SyncUiEventIds.DM_Equipment_Selected, SyncUiEventIds.RM_Bracing_Selected, SyncUiEventIds.RM_Grating_Selected])
                });


                // const defectsMenu = getDefectsMenu();
                // const planningImagesMenu = getPlanningImagesMenu();

                const toolsArr2 = [deleteObject, cloneObject];

                toolbarItems.push(...ToolbarHelper.createToolbarItemsFromItemDefs(toolsArr2, 3000));
                // toolbarItems.push(ToolbarItemUtilities.createGroupButton("clone-equipment", 2000, "icon-clipboard-copy", "Clone Equipment", [enableBackgroundMap, disableBackgroundMap, downRepCom, addEquip], { badgeType: BadgeType.TechnicalPreview }));
                // toolbarItems.push(defectsMenu);
                // toolbarItems.push(ToolbarItemUtilities.createGroupButton("planning-images", 4000, "icon-image", "Planning Images", [enableBackgroundMap, disableBackgroundMap, downRepCom, addEquip], { badgeType: BadgeType.TechnicalPreview }));
                // toolbarItems.push(planningImagesMenu);
                
                // const operatorElevationBtn = new CustomItemDef({
                // popupPanelNode: <OperatorElevationMappingTool/>,
                // iconSpec: "icon-table-2",
                // labelKey: "Elevation-Operator Mapping",
                // tooltipKey: "",
                // // badgeType: BadgeType.TechnicalPreview
                // // isDisabled: !this.state.planRepExist,
                // });


                const operatorElevationBtn = new ToolItemDef({
                    execute: opElExecution,
                    toolId: "OpElButton",
                    isActive: buttonStates.opElMapBtn.active,
                    // iconSpec: <SvgEdit />,
                    iconSpec: <SvgTable />,
                    label: new ConditionalStringValue(() => buttonStates.opElMapBtn.label, [SyncUiEventIds.RM_Enable_Opeartor_Elevation_Mapping]),
                    tooltipKey: "",
                    isDisabled: new ConditionalBooleanValue(() => buttonStates.opElMapBtn.disabled, [SyncUiEventIds.RM_Enable_Opeartor_Elevation_Mapping])
                    });
    

                // const toolsArr3 = [operatorElevationBtn];
                toolbarItems.push(...ToolbarHelper.createToolbarItemsFromItemDefs([operatorElevationBtn], 6000));

                
                const operatorMountFilterButton = new ToolItemDef({
                    execute: opElFilterExecution,
                    toolId: "OpElFilterButton",
                    isActive: buttonStates.opElMapBtn.active,
                    // iconSpec: <SvgEdit />,
                    iconSpec: <SvgFilter />,
                    label: "Operator Filter",
                    tooltipKey: "",
                    isDisabled: new ConditionalBooleanValue(() => buttonStates.opElMapBtn.disabled, [SyncUiEventIds.RM_Enable_Opeartor_Elevation_Mapping])
                });

                toolbarItems.push(...ToolbarHelper.createToolbarItemsFromItemDefs([operatorMountFilterButton], 7000));
                // toolbarItems.push(...ToolbarHelper.createToolbarItemsFromItemDefs(toolsArr3, 6000));
                // toolbarItems.push(ToolbarItemUtilities.createGroupButton("map-group", 100,  IconSpecUtilities.createWebComponentIconSpec("remove.svg"), "Map ViewFlags", [enableBackgroundMap, disableBackgroundMap], { badgeType: BadgeType.New }));
                // toolbarItems.push(ToolbarItemUtilities.createGroupButton("map-group", 100,  IconSpecUtilities.createWebComponentIconSpec("remove.svg"), "Map ViewFlags", [enableBackgroundMap, disableBackgroundMap], { badgeType: BadgeType.New }));
            }
        }

        // Toolbars will appear in the top right
        if (toolbarUsage === ToolbarUsage.ViewNavigation) {
            if (toolbarOrientation === ToolbarOrientation.Horizontal) {
                const enableBackgroundMap = ToolbarItemUtilities.createActionButton("enable-map", 100, IconSpecUtilities.createWebComponentIconSpec("map.svg"), "Enable Background Map",
                (): void => {
                    // If you want to know more about handling ViewFlags, checkout the "View Attributes" sample (https://www.itwinjs.org/sandboxes/iTwinPlatform/View%20Attributes)
                    const vp = IModelApp.viewManager.selectedView;
                    if (vp) {
                    vp.viewFlags = vp.viewFlags.with("backgroundMap", true);
                    }
                });

                const disableBackgroundMap = ToolbarItemUtilities.createActionButton("disable-map", 110, IconSpecUtilities.createWebComponentIconSpec("remove.svg"), "Disable Background Map",
                (): void => {
                    const vp = IModelApp.viewManager.selectedView;
                    if (vp) {
                    vp.viewFlags = vp.viewFlags.with("backgroundMap", false);
                    }
                });
                toolbarItems.push(
                ToolbarItemUtilities.createGroupButton("map-group", 100,  IconSpecUtilities.createWebComponentIconSpec("chevron-down.svg"), "Map ViewFlags", [enableBackgroundMap, disableBackgroundMap], { badgeType: BadgeType.New })
                );
            }

        }
    }
    return toolbarItems;
}