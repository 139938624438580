import { AccessToken, Id64, Id64String } from "@itwin/core-bentley";
import { IModelApp, IModelConnection, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { PropertyRecord, PropertyValueFormat } from "@itwin/appui-abstract";
import "./Table.scss";
import DefectsClient from "../../api/defects";
// import App from "../App";
import { ReduceDecimalNumberToTwoPlaces, ReduceDecimalToTwoPlaces } from "../Lists/EquipmentList";
import { Logger } from "../../api/logging";
import { store } from "../../../store/rootReducer";
import { RootState } from "../../../store/States";
import { ConfigManager } from "../../../config/ConfigManager";

export interface DefectData {
  className: string;
  id: Id64String;

  altitude: number;
  criticality: string;
  inspectionDate: string;
  defect: string;
  defectiveComponent: string;
  defectiveStructure: string;
  intervation: string;
  pitch: number;
  roll: string;
  yaw: string;
  type: string;
  info?: any; 
  typeOfAIAI?:string;
  latitude?:string;
}

let accessToken: AccessToken, projectId: string, openToweriQUrl: string, privateAccessToken: AccessToken;

const listener = () => {
    setParameters(store.getState());
}

function setParameters(state: RootState) {
    accessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
    projectId = ConfigManager.projectId!;
    openToweriQUrl = ConfigManager.openToweriQUrl!;
    privateAccessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
}

store.subscribe(listener);

// tslint:disable:naming-convention
export class DefectsTable {
  private static defectsData: DefectData[];
  private static pinsData: any;

  // private static createRowItem = (record: DefectData) => ({
  //   key: JSON.stringify(TableHelper.createInstanceKey(record)), // just serialize the instance key to string
  //   cells: [
  //     {
  //       key: "type",
  //       record: new PropertyRecord(
  //         { valueFormat: PropertyValueFormat.Primitive, value: record.type },
  //         { name: "type", displayLabel: "Equipment Description", typename: "string" },
  //       ),
  //     },
  //     {
  //       key: "altitude",
  //       record: new PropertyRecord(
  //         { valueFormat: PropertyValueFormat.Primitive, value: ReduceDecimalNumberToTwoPlaces(record.altitude) },
  //         { name: "altitude", displayLabel: "Altitude", typename: "number" },
  //       ),
  //     },
  //     {
  //       key: "defect",
  //       record: new PropertyRecord(
  //         {
  //           valueFormat: PropertyValueFormat.Primitive,
  //           value: record.defect,
  //         },
  //         {
  //           name: "defect",
  //           displayLabel: "Defect",
  //           typename: "string",
  //         },
  //       ),
  //     },
  //     {
  //       key: "defectiveComponent",
  //       record: new PropertyRecord(
  //         {
  //           valueFormat: PropertyValueFormat.Primitive,
  //           value: record.defectiveComponent,
  //         },
  //         {
  //           name: "defectiveComponent",
  //           displayLabel: "Defective Component",
  //           typename: "string",
  //         },
  //       ),
  //     },
  //     {
  //       key: "defectiveStructure",
  //       record: new PropertyRecord(
  //         {
  //           valueFormat: PropertyValueFormat.Primitive,
  //           value: record.defectiveStructure,
  //         },
  //         { name: "defectiveStructure", displayLabel: "Defective Structure", typename: "string" },
  //       ),
  //     },
  //     {
  //       key: "criticality",
  //       record: new PropertyRecord(
  //         { valueFormat: PropertyValueFormat.Primitive, value: record.criticality },
  //         { name: "criticality", displayLabel: "Criticality", typename: "string" },
  //       ),
  //     },
  //     {
  //       key: "pitch",
  //       record: new PropertyRecord(
  //         { valueFormat: PropertyValueFormat.Primitive, value: ReduceDecimalNumberToTwoPlaces(record.pitch) },
  //         { name: "pitch", displayLabel: "Tilt", typename: "number" },
  //       ),
  //     },
  //     {
  //       key: "yaw",
  //       record: new PropertyRecord(
  //         { valueFormat: PropertyValueFormat.Primitive, value: ReduceDecimalToTwoPlaces(record.yaw) },
  //         { name: "yaw", displayLabel: "Azimuth", typename: "string" },
  //       ),
  //     },
  //     {
  //       key: "interventionPriority",
  //       record: new PropertyRecord(
  //         {
  //           valueFormat: PropertyValueFormat.Primitive,
  //           value: record.intervation,
  //         },
  //         { name: "interventionPriority", displayLabel: "Intervention Priority", typename: "string" },
  //       ),
  //     },
  //     {
  //       key: "inspectionDate",
  //       record: new PropertyRecord(
  //         { valueFormat: PropertyValueFormat.Primitive, value: record.inspectionDate },
  //         { name: "inspectionDate", displayLabel: "Date_Time", typename: "string" },
  //       ),
  //     },
  //   ],
  // });
  public static async getPinsData(_imodel: IModelConnection): Promise<DefectData[]> {
    try {
      const recordData = await DefectsClient.getDefectPinsJson(accessToken);
            recordData.map((e:any)=>e.className="Pin_Defect");
      DefectsTable.pinsData = recordData;
      return recordData;
    } catch (e) {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No pin defects found", "", OutputMessageType.Toast));
        return [];
    }
  }

  public static async getData(_imodel: IModelConnection): Promise<DefectData[]> {
    try {
      const records: DefectData[] = [];
      if (DefectsTable.defectsData !== undefined && DefectsTable.defectsData.length > 0) {
        return DefectsTable.defectsData;
      }
      const recordData = await DefectsClient.getDefectsJson(accessToken);
      if (!recordData) {
        return [];
      }
      for await (const record of recordData) {
        record.className = "AI_ML_Defect";
        record.id = _imodel?.transientIds.next;
        records.push(record);
      }
      DefectsTable.defectsData = records;
      return records;
    } catch (e) {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "No auto defected defects", "", OutputMessageType.Toast));
      // tslint:disable-next-line:no-console
       Logger.error("Error while getting defects",e);
        return [];
    }
  }

  // public static createDataProvider = async (imodel: IModelConnection) => {
  //   const columns = new Array<ColumnDescription>();
  //   columns.push({
  //     key: "type",
  //     propertyDescription: {
  //       name: "Type",
  //       displayLabel: "Type",
  //       typename: "string",
  //     },
  //     filterable: true,
  //     filterRenderer: FilterRenderer.MultiSelect,
  //     sortable: true,
  //     label: "Equipment Description",
  //     width: 150,
  //   });
  //   columns.push({
  //     key: "altitude",
  //     sortable: true,
  //     filterable: true,
  //     label: "Altitude",
  //     filterRenderer: FilterRenderer.MultiSelect,
  //   });
  //   columns.push({
  //     key: "defect",
  //     propertyDescription: {
  //       name: "defect",
  //       displayLabel: "Defect",
  //       typename: "string",
  //     },
  //     filterable: true,
  //     filterRenderer: FilterRenderer.MultiSelect,
  //     sortable: true,
  //     label: "Defect",
  //   });
  //   columns.push({
  //     key: "defectiveComponent",
  //     propertyDescription: {
  //       name: "defectiveComponent",
  //       displayLabel: "Defective Component",
  //       typename: "string",
  //     },
  //     filterable: true,
  //     filterRenderer: FilterRenderer.MultiSelect,
  //     sortable: true,
  //     label: "Defective Component",
  //   });
  //   columns.push({
  //     key: "defectiveStructure",
  //     sortable: true,
  //     filterable: true,
  //     label: "Defective Structure",
  //     filterRenderer: FilterRenderer.MultiSelect,
  //   });
  //   columns.push({
  //     key: "criticality",
  //     propertyDescription: {
  //       name: "criticality",
  //       displayLabel: "Criticality",
  //       typename: "string",
  //     },
  //     filterable: true,
  //     filterRenderer: FilterRenderer.MultiSelect,
  //     sortable: true,
  //     label: "Criticality",
  //   });
  //   columns.push({
  //     key: "pitch",
  //     propertyDescription: {
  //       name: "pitch",
  //       displayLabel: "Pitch",
  //       typename: "number",
  //     },
  //     filterable: true,
  //     filterRenderer: FilterRenderer.MultiSelect,
  //     sortable: true,
  //     label: "Tilt (" + "\u00b0" + ")",
  //   });
  //   columns.push({
  //     key: "yaw",
  //     propertyDescription: {
  //       name: "yaw",
  //       displayLabel: "Yaw",
  //       typename: "string",
  //     },
  //     filterable: true,
  //     filterRenderer: FilterRenderer.MultiSelect,
  //     sortable: true,
  //     label: "Azimuth (" + "\u00b0" + ")",
  //   });
  //   columns.push({
  //     key: "interventionPriority",
  //     sortable: true,
  //     filterable: true,
  //     label: "Intervention Priority",
  //     filterRenderer: FilterRenderer.MultiSelect,
  //   });
  //   columns.push({
  //     key: "inspectionDate",
  //     propertyDescription: {
  //       name: "inspectionDate",
  //       displayLabel: "Date",
  //       typename: "string",
  //     },
  //     filterable: true,
  //     filterRenderer: FilterRenderer.MultiSelect,
  //     sortable: true,
  //     label: "Date",
  //     width: 150,
  //   });

  //   const rows = new Array<RowItem>();
  //   const rowData = await DefectsTable.getData(imodel);
  //   rowData.forEach((row: any) => {
  //     Object.keys(row).map((key) => {
  //       if (typeof row[key] === "number") {
  //         row[key] = ReduceDecimalNumberToTwoPlaces(row[key]);
  //       }
  //     });
  //     rows.push(DefectsTable.createRowItem(row));
  //   });

  //   const provider = new SimpleTableDataProvider(columns);
  //   provider.setItems(rows);
  //   return provider;
  // };
}
