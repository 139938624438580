import { ConditionalBooleanValue, ToolbarItemUtilities } from "@itwin/appui-abstract";
import { RenderMode } from "@itwin/core-common";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
// import { CustomToolbarProvider } from "../CustomToolbarProvider";
import EquipmentClient from "../api/equipment";
import { HighlightMountTool } from "../components/tools/HighlightMountTool";
import { DTVActions } from "../../store/Actions";
import { RootState } from "../../store/States";
import { setSiteCoordinate, setTowerData, setMountingsData, setMountingsData3DMaps, addToBuilt3DObjectIdsMap, setSelectedObjectStructure } from "../../store/detectedData/apiDataActionTypes";
import { ObjectCategoryTypes, initialPropertyListObjectState, mountingsData } from "../../store/detectedData/apiDataTypes";
import { store } from "../../store/rootReducer";
import { MountDecorator } from "./decorators/MountDecorator";
import { SyncUiEventIds } from "../../store/redux-types";
import { editOnClose } from "../components/HorizontalToolbarItems";
import { SyncUiEventDispatcher } from "@itwin/appui-react";

function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}
  
export const HighlightMount = (_buttonStates: any) => {

    return ToolbarItemUtilities.createActionButton(
        "Highlight-mount", 
        110, 
        "icon-spatial-view-apply", 
        "Highlight Mounts",
        async () => await executeHighlightMount(true),
        // {isDisabled: new ConditionalBooleanValue(() => buttonStates.enableHighlightMount.disabled, [SyncUiEventIds.Enable_Highlight_Mount, SyncUiEventIds.Disable_Highlight_Mount])}
    );





    // async () => {
    //     // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_EQUIPMENT");
    //     // if (!this._isInsiteSupported) {
    //     //   this.showNotSupportedNotification();
    //     //   return;
    //     // }
    //     // this.siteCoord;
    //     if (!siteCoordinates) {
    //         const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
    //         store.dispatch(setSiteCoordinate(siteCoord));
    //     }
    //     if (!SampleToolWidget.equipNamePositionMap || SampleToolWidget.equipNamePositionMap.size === 0) {
    //         IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Equipment detected in this tower.`, "", OutputMessageType.Toast));
    //         return;
    //     }
    //     let towerStructure = select(store.getState(), "towerStructureData");
        
    //     if (!towerStructure) {
    //         towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
    //         store.dispatch(setTowerData(towerStructure));
    //     }

    //     const vp = IModelApp.viewManager.selectedView;
    //     if (vp) {
    //         const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
    //         vp.viewFlags = viewFlags;
    //     }

    //     let toolRetVal: boolean = false;

    //     setTimeout(async () => {
    //         toolRetVal = await IModelApp.tools.run(OutlineEquipmentTool.toolId);
    //         // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //         if(toolRetVal){
    //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Equipment are successfully loaded.", "", OutputMessageType.Toast));
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: ["All"]}});
    //         } else {
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: []}});
    //         }
    //         // this.setState({highlightStates: {...this.state.highlightStates, equipment: toolRetVal}});
    //         // this._resetOperatorFilterWidget();
    //     }, 0);

    //     // tslint:disable-next-line:tslint-react-set-state-usage
    //     // if (this.state.isVisible) {
    //         //   this.setState({ isVisible: false });
    //         // }
    //         // if (!this.state.disableCloneEquipment) {
    //     //   this.setState({ disableCloneEquipment: true });
    //     // }
    //     // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //     // this.setState({ disableEditEquipment: !this.state.disableEditEquipment });
    // });
};
 export const executeHighlightMount = async (showMessage:boolean = true) => {
    const siteCoordinates = select(store.getState(), "siteCoordinate");

    // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_MOUNT");
    // if (!this._isInsiteSupported) {
    //   this.showNotSupportedNotification();
    //   return;
    // }
    if (!siteCoordinates) {
        const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
        if(siteCoord)store.dispatch(setSiteCoordinate(siteCoord));
    }

    let mountsData: mountingsData = select(store.getState(), "mountingsData");
    let towerStructure = select(store.getState(), "towerStructureData");
    if(store.getState().dtvState.featureControls.restrictCrudOperations && !store.getState().dtvState.applicationState.isLoggedInUserAdmin)
        SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Not_Show_All_Detected_Objects);    
    if (!towerStructure) {
        towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
        if(towerStructure)store.dispatch(setTowerData(towerStructure));
    }

    if(!Object.entries(mountsData.mounts).length){
        mountsData = await EquipmentClient.getMountingsDataJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken);
        if(mountsData)store.dispatch(setMountingsData(mountsData));
    }
    
    const vp = IModelApp.viewManager.selectedView;
    if (vp) {
        const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
        vp.viewFlags = viewFlags;
    }

    const currentState = store.getState();
    if(currentState.dtvState.applicationState.highlightStates.mount){
        store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, mount: false}));
        if(currentState.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Mount_RM)store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
        executeUnhighlightMount()
        return;
    }
    if (mountsData !== null) {
        // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedMounts: ["All"]}});
        // SampleToolWidget.mountsData = mountsData;
        // mountsData.towerData = towerStructure;
        let toolRetVal: boolean=false;
        // setTimeout(async () => {
            toolRetVal = await IModelApp.tools.run(HighlightMountTool.toolId, mountsData, true, ["All"]);
            if(toolRetVal){                        
                const md = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;
                store.dispatch(setMountingsData3DMaps(new Map(md.nameIdMap)));
                store.dispatch(addToBuilt3DObjectIdsMap(new Map(md.objectIdMap)));
            }
        //   (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
        //   CoreTools
        // }, 0);
        // if (toolRetVal)this.setState({ isMountVisible: true});
        // this.setState({highlightStates: {...this.state.highlightStates, mount: true}});
        store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, mount: true}));
    } else {
       if(showMessage) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Could not fetch the mounts data", "", OutputMessageType.Toast));
        return;
    }
    // } else {
    // //   this.setState({isMountVisible: false});
    // //   this.setState({highlightStates: {...this.state.highlightStates, mount: false}})
    //   this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedMounts: ["None"]}})
    //   IModelApp.tools.run(HighlightMountTool.toolId, [], false);
    // }
}

export const executeUnhighlightMount = () => {
    const currentState = store.getState();

    IModelApp.tools.run(HighlightMountTool.toolId, [], false);
    editOnClose("MountDecorator", true);
    return;
}
// store.subscribe(HighlightMount);