import { OperationVariables } from "@apollo/client";
import { Logger } from "../../../logging/logging";
import { ExecuteGraphQLMutation, ExecuteGraphQLQuery } from "../../client/apollo-client";
import { towerCapabilities, projectAttributes,mountCatalogInfo,retrieveMountCatalogSharedAccessKey,mountiModelModifyRequestMutation,mountiModelModifyRequestV1Mutation, retrieveElevationOperatorMapping, elevationOperatorMappingMutation, addOnProductsForOrganization, projectAddOnProductLicense, towerTier, globalAddOnProducts, projectAddOnProducts,lastRunDetailsSetting, projectStatus, retrieveTowerElevationSetting, updateTowerElevationSetting, GetProjectByIdQuery, addUserRequest, postUserRequestProcessMessage, userRequestById} from "./database-api-query";
import { mapElevation } from "../../../dtv-features/components/tools/AdjustElevationTool";
import { UserRequest, UserRequestSource } from "./userRequest";

interface MountDetailsProperties { manufacturer: string, modelName: string, mountType:string }
export interface OrganizationAddOnProductProjectInputType {ultimateId: string, towerId: string, projectId: string, addOnProductId: number}

export default class DatabaseApiClient {
    public static async getTowerCapabilities(towerId: string) {
        Logger.info("Started calling get tower capabilities in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "getTowerCapabilities"
        });

        let variables: OperationVariables = {
            towerId: towerId,
            applicationId: 2
        };

        var response = await ExecuteGraphQLQuery(towerCapabilities, "towerCapabilities", variables);

        Logger.info("Success calling tower capabilities in {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "getTowerCapabilities"
        });

        return response.towerCapabilities;
    }
    public static async getProjectAttributes(projectId: String) {
        Logger.info("Started calling get project attributes in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "getProjectAttributes"
        });

        let variables: OperationVariables = {
            projectId: projectId
        };

        var response = await ExecuteGraphQLQuery(projectAttributes, "projectAttributes", variables);

        Logger.info("Success calling get project attributes in {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "getProjectAttributes"
        });

        return response.projectAttributes;
    }

    public static async getProjectStatus(projectId: String) {
        Logger.info("Started calling get project status in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "getProjectStatus"
        });

        let variables: OperationVariables = {
            projectId: projectId
        };

        var response = await ExecuteGraphQLQuery(projectStatus, "projectStatus", variables);

        Logger.info("Success calling get project status in {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "getProjectStatus"
        });

        return response;
    }

    public static async mountModel(type: string, ultimateId: string) {
        Logger.info("Started calling retrieve mountCatalogInfo properties in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "mountCatalogInfo"
        });

        let variables: OperationVariables = {
            mountType: type,
            ultimateId: ultimateId
        };
        var response = await ExecuteGraphQLQuery(mountCatalogInfo, "mountModel",variables);

        Logger.info("Success calling retrieve mountCatalogInfo properties in {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "mountCatalogInfo"
        });

        return response;
    }
    
    public static async retrieveMountCatalogSharedAccessKey(mountDetails : MountDetailsProperties) {
        Logger.info("Started calling retrieveMountInfoFromCatalog for getting MountCatalogSharedAccessKey in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveMountInfoFromCatalog"
        });

        let variables: OperationVariables = {
            mountDetails: mountDetails
        };

        var response = await ExecuteGraphQLQuery(retrieveMountCatalogSharedAccessKey, "retrieveMountCatalogSharedAccessKey", variables);

        Logger.info("Success calling retrieveMountInfoFromCatalog for getting MountCatalogSharedAccessKey in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveMountInfoFromCatalog"
        });

        return response.retrieveMountCatalogSharedAccessKey;
    }

    public static async mountImodelModify(mountDetails: any,projectId): Promise<any> {

        Logger.info("Started calling iModelVersionDetails api in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveMountInfoFromCatalog"
        });
        let variables = {
            mountDetails,
            projectId
        }
        var response = await ExecuteGraphQLMutation(mountiModelModifyRequestMutation, "mountiModelModifyRequestMutation", variables);

        Logger.info("Success calling iModelVersionDetails api from {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveMountInfoFromCatalog"
        });
        return response.mountiModelModifyRequest;
    }
    public static async mountiModelModifyV1(mountDetails: any,projectId): Promise<any> {

        Logger.info("Started calling iModelVersionDetails api in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveMountInfoFromCatalog"
        });
        let variables = {
            mountDetails,
            projectId
        }
        var response = await ExecuteGraphQLMutation(mountiModelModifyRequestV1Mutation, "mountiModelModifyRequestV1Mutation", variables);

        Logger.info("Success calling iModelVersionDetails api from {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveMountInfoFromCatalog"
        });
        return response.mountiModelModifyRequestV1;
    }
    public static async getLastRunDetails(projectId: string) {
        Logger.info("Started calling retrieve getLastRunDetails in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "getLastRunDetails"
        });

        let variables: OperationVariables = {
            projectId: projectId
        };

        var response = await ExecuteGraphQLQuery(lastRunDetailsSetting, "lastRunDetailsSetting", variables);

        Logger.info("Success calling retrieve getLastRunDetails in {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "getLastRunDetails"
        });

        return response;
    }

    
    public static async retrieveElevationOperatorMapping(contextId : string) {
        Logger.info("Started calling retrieveElevationOperatorMapping for getting Operator Elevation Mapping details in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveElevationOperatorMapping"
        });

        let variables: OperationVariables = {
            contextId: contextId
        };
        var response = await ExecuteGraphQLQuery(retrieveElevationOperatorMapping, "retrieveElevationOperatorMapping", variables);

        Logger.info("Success calling retrieveElevationOperatorMapping for getting Operator Elevation Mapping details in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveElevationOperatorMapping"
        });

        return response.towerOperatorsSetting;
    }
    
    public static async retrieveTowerElevationSetting(contextId : string) {
        Logger.info("Started calling retrieveElevationOperatorMapping for getting Operator Elevation Mapping details in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveElevationOperatorMapping"
        });

        let variables: OperationVariables = {
            contextId: contextId
        };
        var response = await ExecuteGraphQLQuery(retrieveTowerElevationSetting, "retrieveElevationOperatorMapping", variables);

        Logger.info("Success calling retrieveElevationOperatorMapping for getting Operator Elevation Mapping details in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "retrieveElevationOperatorMapping"
        });

        return response.towerElevationSetting;
    }

    public static async updateTowerElevationSetting(elevation: mapElevation, projectId: string): Promise<any> {

        Logger.info("Started calling elevationOperatorUpdate api in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "UpdateElevationOperatorMapping"
        });
        let variables = {
            "contextId": projectId,
            "properties": {
              "flatMapElevation": elevation.flatMapElevation,
              "terrainElevation": elevation.terrainElevation
            }
          }
        var response = await ExecuteGraphQLMutation(updateTowerElevationSetting, "updateTowerOperatorsSetting", variables);

        Logger.info("Success calling elevationOperatorUpdate api from {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "updateTowerOperatorsSetting"
        });
        return response.TowerElevationSettingPropertiesType;
    }

    public static async getProjectName(projectId: string) {
        let data: any = [];
        Logger.info(
            "Started calling get projectName api in {fileName} class: {className} method: {methodName}",
            {
                fileName: "database-api-client.ts",
                className: "DatabaseApiClient",
                methodName: "getProjectName"
            }
        );

        var response = await ExecuteGraphQLMutation(GetProjectByIdQuery, "projectById", {instanceId: projectId});
 

        Logger.info(
            "Success calling context registry get projectName api in {fileName}, class: {className}, method: {methodName}",
            {
                fileName: "database-api-client.ts",
                className: "DatabaseApiClient",
                methodName: "getProjectName"
            }
        );
        return response.projectById[0];
    }

    public static async elevationOperatorUpdate(opElDetails: any, projectId: string): Promise<any> {

        Logger.info("Started calling elevationOperatorUpdate api in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "UpdateElevationOperatorMapping"
        });
        let variables = {
            "contextId": projectId,
            "properties": {
              "operators": opElDetails
            }
          }
        var response = await ExecuteGraphQLMutation(elevationOperatorMappingMutation, "updateTowerOperatorsSetting", variables);

        Logger.info("Success calling elevationOperatorUpdate api from {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "updateTowerOperatorsSetting"
        });
        return response.updateTowerOperatorsSetting;
    }

    public static async handleProjectAddOnProductLicense(addOnProductProject: OrganizationAddOnProductProjectInputType): Promise<any> {
        Logger.info("Started calling handleProjectAddOnProductLicense api in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "handleProjectAddOnProductLicense"
        });
        let variables: OrganizationAddOnProductProjectInputType = {
            "ultimateId": addOnProductProject.ultimateId,
            "towerId": addOnProductProject.towerId,
            "projectId": addOnProductProject.projectId,
            "addOnProductId":addOnProductProject.addOnProductId
            };
        var response = await ExecuteGraphQLMutation(projectAddOnProductLicense, "handleProjectAddOnProductLicense", {"addOnProductProject": variables});
        Logger.info("Success calling handleProjectAddOnProductLicense api from {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "handleProjectAddOnProductLicense"
        });
        return response.handleProjectAddOnProductLicense;
    }

    public static async getOrganizationAddOnProducts(ultimateRefId : string | undefined) {
        Logger.info("Started calling organizationAddOnProducts for Enabling the License specific tools in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "organizationAddOnProducts"
        });
        let variables: OperationVariables = {
            ultimateRefId: ultimateRefId
        };
        var response = await ExecuteGraphQLQuery(addOnProductsForOrganization, "organizationAddOnProducts", variables);
        Logger.info("Success calling organizationAddOnProducts for Enabling the License specific tools in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "organizationAddOnProducts"
        });
        return response.organizationAddOnProductsByUltimateRefId;
    }

    public static async getProjectAddOnProducts(projectId : string | undefined) {
        Logger.info("Started calling getProjectAddOnProducts to check for a license used by the project in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "organizationAddOnProducts"
        });
        let variables: OperationVariables = {
            projectId: projectId
        };
        var response = await ExecuteGraphQLQuery(projectAddOnProducts, "organizationAddOnProducts", variables);
        Logger.info("Success calling getProjectAddOnProducts to check for a license used by the project in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "organizationAddOnProducts"
        });
        return response.projectAddOnProducts;
    }

    public static async getGlobalAddOnProducts() {
        Logger.info("Started calling globalAddOnProducts for Enabling the License specific tools in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "globalAddOnProducts"
        });
        var response = await ExecuteGraphQLQuery(globalAddOnProducts, "globalAddOnProducts");
        Logger.info("Success calling globalAddOnProducts for Enabling the License specific tools in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "globalAddOnProducts"
        });
        return response.addOnProducts;
    }

    public static async handleTowerTier(towerId : string | undefined) {
        Logger.info("Started calling towerTier for Enabling the License specific tools in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "towerTier"
        });
        let variables: OperationVariables = {
            towerId: towerId
        };
        var response = await ExecuteGraphQLQuery(towerTier, "towerTier", variables);
        Logger.info("Success calling towerTier for getting Operator Elevation Mapping details in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "towerTier"
        });
        return response.towerTier;
    }

    public static async addUserRequest(userRequest: UserRequest) {
        Logger.info("Started calling addUserRequest api from {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "addUserRequest"
        });

        let variables: OperationVariables = {
            userRequest
        };

        var response = await ExecuteGraphQLMutation(addUserRequest, "addUserRequest", variables);

        Logger.info("Success calling addUserRequest api from {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "addUserRequest"
        });

        return response.addUserRequest;
    };

    public static async postUserRequestProcessMessage(userRequestId: string, source: UserRequestSource) {
        Logger.info("Started calling postUserRequestProcessMessage api from {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "postUserRequestProcessMessage"
        });

        let variables: OperationVariables = {
            userRequestId,
            source
        };

        var response = await ExecuteGraphQLMutation(postUserRequestProcessMessage, "postUserRequestProcessMessage", variables);

        Logger.info("Success calling postUserRequestProcessMessage api from {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "postUserRequestProcessMessage"
        });

        return response.postUserRequestProcessMessage;
    };
    
    public static async UserRequestById(userRequestId: string) {
        Logger.info("Started calling get User Response Data by Id in {fileName} class: {className} method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "UserRequestById"
        });

        let variables: OperationVariables = {
            userRequestId: userRequestId
        };

        var response = await ExecuteGraphQLQuery(userRequestById, "userRequestById", variables);

        Logger.info("Success calling get User Request By Id in {fileName}, class: {className}, method: {methodName}", {
            fileName: "database-api-client.ts",
            className: "DatabaseApiClient",
            methodName: "UserRequestById"
        });

        return response;
    }
}